import { FORM_VALUE_ENUM, FORM_VALUE_INTER } from './constant';

export const convert = (data: FORM_VALUE_INTER) => ({
  [FORM_VALUE_ENUM.NAME]: data[FORM_VALUE_ENUM.NAME].trim(),
  [FORM_VALUE_ENUM.BUDGET]: Number(data[FORM_VALUE_ENUM.BUDGET]),
  [FORM_VALUE_ENUM.REST_BUDGET]: Number(data[FORM_VALUE_ENUM.REST_BUDGET]),
  ...(data[FORM_VALUE_ENUM.UNIQUE_NUMBER] && {
    [FORM_VALUE_ENUM.UNIQUE_NUMBER]: Number(
      data[FORM_VALUE_ENUM.UNIQUE_NUMBER],
    ),
  }),
});
