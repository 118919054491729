import React from 'react';

import { FormikValues } from 'formik';

import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import styled from 'styled-components';
import { i18n } from '../../lib/lang';
import { TextElem } from '../../common/text';
import { DoubleContainerElem } from '../../common/double-container';
import closeIcon from '../../asset/svg/common/close.svg';
import { FORM_VALUE_ENUM } from '../project-item-create/constant';
import { InputNumber } from 'antd';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { FieldNumberElem } from '../../common/field-number';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  close: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  close,
}) => {
  return (
    <FormElem onSubmit={formik.handleSubmit}>
      {isLoading && <LoaderElem />}

      <GridElem spacing={5}>
        <TitleContainer>
          <TextElem tid="PROJECT.UPDATE.TITLE" type="semi-bold" size="main" />
          <Icon src={closeIcon} onClick={() => close()} />
        </TitleContainer>
        <DoubleContainerElem>
          <FieldTextElem
            name={FORM_VALUE_ENUM.NAME}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="PROJECT.FORM.NAME"
            value={getFieldValue(FORM_VALUE_ENUM.NAME)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
            error={isFieldError(FORM_VALUE_ENUM.NAME)}
          />

          <FieldNumberElem
            name={FORM_VALUE_ENUM.BUDGET}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="PROJECT.FORM.BUDGET"
            value={getFieldValue(FORM_VALUE_ENUM.BUDGET)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.BUDGET)}
            error={isFieldError(FORM_VALUE_ENUM.BUDGET)}
          />
        </DoubleContainerElem>

        <DoubleContainerElem>
          <FieldNumberElem
            name={FORM_VALUE_ENUM.REST_BUDGET}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="PROJECT.FORM.REST_BUDGET"
            value={getFieldValue(FORM_VALUE_ENUM.REST_BUDGET)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.REST_BUDGET)}
            error={isFieldError(FORM_VALUE_ENUM.REST_BUDGET)}
          />
          <FieldNumberElem
            name={FORM_VALUE_ENUM.UNIQUE_NUMBER}
            title="PROJECT.FORM.UNIQUE_NUMBER"
            value={formik.values[FORM_VALUE_ENUM.UNIQUE_NUMBER]}
            onChange={formik.handleChange}
            disableDecimal
            errorMessage={getFieldError(FORM_VALUE_ENUM.REST_BUDGET)}
            error={isFieldError(FORM_VALUE_ENUM.REST_BUDGET)}
          />
        </DoubleContainerElem>

        <DoubleContainerElem>
          <div></div>
          <ButtonStyled
            type="submit"
            tid="PROJECT.UPDATE.BUTTON"
            fill="solid"
            disabled={isSubmitDisabled()}
            color="success"
          />
        </DoubleContainerElem>

        {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
        {isSuccess && <AlertActionElem type="success" tid="Success" />}
      </GridElem>
    </FormElem>
  );
};

const FieldTextStyled = styled(InputNumber)`
  box-sizing: border-box;
  cursor: pointer;

  position: relative;

  :focus-visible {
    outline: none;
  }

  height: 48px;

  *:has(+ &) {
    transition: all 0.2s;
  }
  div {
    height: 100%;
    width: 100%;
  }

  input {
    padding: 0 16px;
    height: 100%;
    width: 100%;

    font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.MEDIUM]};

    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
    line-height: 1em;
  }

  text-align: start;
  color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
  background-color: ${({ theme }) => theme[COLOR_ENUM.SECONDARY_INPUT]};

  caret-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;

  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  width: 96px;

  border: 1.5px solid ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};

  &::placeholder {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PLACEHOLDER]};
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.SECONDARY_INPUT]} !important;

    border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_HOVER]} !important;
  }

  &:focus-within {
    background-color: ${({ theme }) => theme[COLOR_ENUM.INPUT]};
    border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]};
  }

  .ant-input-number-handler-wrap {
    display: none;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;

const ButtonStyled = styled(ButtonElem)``;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Icon = styled.img`
  cursor: pointer;
`;
