import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { TextElem } from '../text';
import { PROPS_TYPE } from './constant';
import { i18n } from '../../lib/lang';

import { GridElem } from '../grid';
import { InputNumber } from 'antd';

export const Elem: React.FC<PROPS_TYPE> = ({
  title,
  error,
  errorMessage,
  onChange,
  onBlur,
  value,
  name,
  disabled,
  icon,
  placeholder = '',
  className,
  min = 0,
  max,
  style,
  required,
  disableDecimal = false,
  ...props
}) => {
  const refIcon = useRef<HTMLDivElement>(null);

  const handleChange = (value: any) => {
    if (value === undefined || value === null) {
      return;
    }

    const e = { target: { value, name } };

    if (onChange) {
      onChange(e);
    }
  };

  const handleBlur = (value: any) => {
    if (value === undefined || value === null) {
      return;
    }

    const e = { target: { value, name } };

    if (onBlur) {
      onBlur(e);
    }
  };

  const iconSize = refIcon?.current?.clientWidth || 40;
  if (disabled) {
    return (
      <GridElem spacing={3}>
        {title && (
          <TextElem
            tid={title}
            color="textPrimary"
            style={{ height: '14px' }}
          />
        )}
        <FakeInput>
          <TextElem
            tid={value ? value : placeholder}
            color={value ? 'default' : 'textPlaceholder'}
          />
          <Icon ref={refIcon}>{icon}</Icon>
        </FakeInput>
      </GridElem>
    );
  } else {
    return (
      <Container className={className} style={style}>
        <Content>
          {title && (
            <p
              style={{
                height: '14px',
                display: 'block',
                marginBottom: '12px',
              }}
            >
              <TextElem tid={title} color="textPrimary" />
              {required && (
                <TextElem tid="*" color="error" style={{ height: '14px' }} />
              )}
            </p>
          )}

          <>
            {/* @ts-ignore */}
            <CustomInput
              name={name}
              onChange={handleChange}
              onBlur={handleBlur}
              error={error}
              value={value}
              iconSize={iconSize}
              placeholder={placeholder && i18n.t(placeholder)}
              className={className}
              min={min}
              type="number"
              max={max}
              required={required}
              onKeyPress={(event) => {
                if (disableDecimal && !/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              // stringMode
              {...props}
            />
            <div id="content" />
            <Icon ref={refIcon}>{icon}</Icon>
          </>
        </Content>

        {(error || errorMessage) && (
          <TextElem size="alert" color="error">
            {errorMessage}
          </TextElem>
        )}
      </Container>
    );
  }
};

export const CustomInput = styled(InputNumber)<{
  error?: boolean;
  onIonChange?: Function;
  type?: string;
  iconSize?: number;
  required?: boolean;
}>`
  box-sizing: border-box;

  cursor: pointer;
  height: 46px;

  position: relative;

  text-align: start;

  background: ${({ theme }) => theme[COLOR_ENUM.INPUT]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  width: 100%;

  *:has(+ &) {
    transition: all 0.2s;
  }

  ${({ error }) =>
    error &&
    css`
      border-color: ${({ theme }) => theme[COLOR_ENUM.ERROR]} !important;
      color: ${({ theme }) => theme[COLOR_ENUM.ERROR]} !important;
    `}

  .ant-input-number-input {
    line-height: 1em;
    height: 46px;

    caret-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_DEFAULT]};
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_DEFAULT]};

    font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.REGULAR]};

    padding: 0 ${Spacing(4)};

    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;

    @media screen and (width<=600px) {
      font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MOBILE_DEFAULT]}px;
    }
  }

  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]};

  &::placeholder {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PLACEHOLDER]};
  }

  &:hover {
    border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_HOVER]} !important;
    background: ${({ theme }) => theme[COLOR_ENUM.INPUT]};

    *:has(~ &) {
      color: ${({ theme }) => theme[COLOR_ENUM.INPUT_TITLE]} !important;
    }
  }

  &:focus-within {
    background: ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};
    border-color: ${({ theme }) => theme[COLOR_ENUM.ACTIVE]} !important;
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_ACTIVE]};
    *:has(~ &) {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
    }
    ::placeholder {
      display: none;
      color: ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};
    }
  }

  :focus-visible {
    outline: none;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
`;

const FakeInput = styled.div`
  padding: 0 ${Spacing(4)};
  position: relative;
  height: 46px;
  font-size: ${({ theme }) => theme[SIZE_FONT_ENUM.INPUT]}px;
  @media screen and (width<=600px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MOBILE_DEFAULT]}px;
  }
  color: ${({ theme }) => theme[COLOR_ENUM.TEXT_DISABLED]};
  background: ${({ theme }) => theme[COLOR_ENUM.INPUT]};
  line-height: 1em;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_DISABLED]};
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.04);
  && > span {
    width: calc(100% - 40px);
    display: block;
    overflow: hidden;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }
`;

const Content = styled.div`
  input {
    transition: all 0.2s;
  }
  span {
    transition: all 0.2s;
  }

  position: relative;
`;

const Container = styled.div`
  display: grid;
  grid-gap: ${Spacing(2)};
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PRIMARY]};
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme }) => theme[COLOR_ENUM.INPUT]}
      inset;
  }
`;

const Icon = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: max-content;
  box-sizing: content-box;
  z-index: 2;
`;
