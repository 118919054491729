import React from 'react';
import { IonApp, IonRouterOutlet, isPlatform } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route, Switch } from 'react-router';
import { SettingsPage, SETTINGS_PAGE_PATH } from '../settings';

import { useSelector } from '../../lib/store';
import { AUTH_MODULE_NAME } from '../../data/auth';

import {
  AUTH_RECOVERY_CONFIRM_PAGE_PATH,
  AuthRecoveryConfirmPage,
} from '../auth-recovery-confirm';

import {
  AUTH_RECOVERY_PASSWORD_PAGE_PATH,
  AuthRecoveryPasswordPage,
} from '../auth-recovery-password';

import { STORE_INTER } from '../../data/auth/constant';

import { AUTH_LOGIN_PAGE_PATH, AuthLoginPage } from '../auth-login';
import { AUTH_RECOVERY_PAGE_PATH, AuthRecoveryPage } from '../auth-recovery';

import { ADMIN_PROJECT_PAGE_PATH, AdminProjectPage } from '../admin-project';
import { ADMIN_USER_PAGE_PATH, AdminUserPage } from '../admin-user';
import { USER_ROLE } from '../../data/user/constant';

import {
  EXPENDITURE_CREATE_PAGE_PATH,
  ExpenditureCreatePage,
} from '../expenditure-item-create';
import {
  EXPENDITURE_LIST_PAGE_PATH,
  ExpenditureListPage,
} from '../expenditure-item';

export const Page: React.FC = () => {
  const state: STORE_INTER = useSelector((s) => s[AUTH_MODULE_NAME]);

  const isLogged = () => state.logged;

  const isAdmin = () => {
    return state?.user?.role === USER_ROLE.ADMIN;
  };

  const isManager = () => {
    return state?.user?.role === USER_ROLE.MANAGER;
  };

  const isAccountant = () => {
    return state?.user?.role === USER_ROLE.ACCOUNTANT;
  };

  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          {isLogged() ? (
            <Switch>
              <Route path={SETTINGS_PAGE_PATH} component={SettingsPage} exact />

              {isManager() && (
                <Route
                  path={EXPENDITURE_CREATE_PAGE_PATH}
                  component={ExpenditureCreatePage}
                  exact
                />
              )}
              {isManager() && <Redirect to={EXPENDITURE_CREATE_PAGE_PATH} />}
              {isAccountant() && (
                <Route
                  path={EXPENDITURE_LIST_PAGE_PATH}
                  component={ExpenditureListPage}
                  exact
                />
              )}
              {isAccountant() && <Redirect to={EXPENDITURE_LIST_PAGE_PATH} />}

              {/* <Route path={WELLCOME_PAGE_PATH} component={WellcomePage} exact /> */}

              {isAdmin() && (
                <Route
                  path={EXPENDITURE_CREATE_PAGE_PATH}
                  component={ExpenditureCreatePage}
                  exact
                />
              )}

              {isAdmin() && (
                <Route
                  path={EXPENDITURE_LIST_PAGE_PATH}
                  component={ExpenditureListPage}
                  exact
                />
              )}

              {isAdmin() && (
                <Route
                  path={ADMIN_PROJECT_PAGE_PATH}
                  component={AdminProjectPage}
                  exact
                />
              )}
              {isAdmin() && (
                <Route
                  path={EXPENDITURE_CREATE_PAGE_PATH}
                  component={ExpenditureCreatePage}
                  exact
                />
              )}
              {isAdmin() && (
                <Route
                  path={ADMIN_USER_PAGE_PATH}
                  component={AdminUserPage}
                  exact
                />
              )}
              {isAdmin() && <Redirect to={EXPENDITURE_LIST_PAGE_PATH} />}
            </Switch>
          ) : (
            <Switch>
              <Route
                path={AUTH_RECOVERY_CONFIRM_PAGE_PATH}
                component={AuthRecoveryConfirmPage}
                exact
              />
              <Route
                path={AUTH_RECOVERY_PASSWORD_PAGE_PATH}
                component={AuthRecoveryPasswordPage}
                exact
              />
              <Route
                path={AUTH_RECOVERY_PAGE_PATH}
                component={AuthRecoveryPage}
                exact
              />
              <Route
                path={AUTH_LOGIN_PAGE_PATH}
                component={AuthLoginPage}
                exact
              />

              <Redirect to={AUTH_LOGIN_PAGE_PATH} />
            </Switch>
          )}
        </IonRouterOutlet>
      </IonReactRouter>
    </IonApp>
  );
};
