import React from 'react';

import { FieldArray, FormikProvider } from 'formik';

import { FORM_VALUE_ENUM } from './constant';

import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import { i18n } from '../../lib/lang';

import { ContentContainerElem } from '../../common/content-container';
import { DoubleContainerElem } from '../../common/double-container';

import { FieldDataTimeInputElem } from '../../common/field-datetime-input';
import {
  EXPENDITURE_BILL_OPTION_LIST,
  EXPENDITURE_VALUTE_OPTION_LIST,
} from '../../data/expenditure/constant';
import { SelectElem } from '../../common/select';
import { SelectPreloadContainer } from '../select-preload';
import { FieldTextAreaElem } from '../../common/field-text-area';
import { TextElem } from '../../common/text';
import { FileItemUploadContainer } from '../file-item-upload';

import {
  getCategoryExpenseList,
  getExpenditureProjectList,
} from '../../data/expenditure/action';
import { useSelector } from '../../lib/store';
import { THEME_MODULE_NAME } from '../../data/theme';
import CloseIcon from '../../asset/svg/common/close.svg';
import addIcon from '../../asset/svg/button/plus-light.svg';
import addIconLight from '../../asset/svg/button/plus.svg';
import styled from 'styled-components';
import { Spacing } from '../../theme';
import { ReactComponent as DeleteIcon } from '../../asset/svg/common/close.svg';
import { THEME_ENUM } from '../../data/theme/constant';
import { DividerElem } from '../../common/divider';
import { IonSkeletonText } from '@ionic/react';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { VALUE_OPACITY_ENUM } from '../../theme/value';
import {
  convertCategoryToSelect,
  convertExpenditureListToSelect,
} from '../../data/expenditure/convert';
import { convertUserListToSelect } from '../../data/user/convert';
import { getAllUserList } from '../../data/user/action';
import { convertProjectListToSelect } from '../../data/project/convert';
import { getProjectList } from '../../data/project/action';
import { FieldNumberElem } from '../../common/field-number';
import { preciseCalculation } from '../../lib/util/calculate';

export const Component: React.FC<{
  formik: any;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isError?: boolean;
  errorMessage?: string;
  setFieldValue: Function;
  setValue: Function;
  isSuccess?: boolean;
  onSuccessUpload: Function;
  budget: any;
  userData: any;
  checkBudget: boolean;
  isAdmin: boolean;
  uniqueNumber?: string;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isError,
  errorMessage,
  setFieldValue,
  setValue,
  isSuccess,
  onSuccessUpload,
  budget,
  userData,
  checkBudget,
  isAdmin,
  uniqueNumber,
}) => {
  const { theme } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));

  const getDifferenceAmount = () => {
    if (formik.values[FORM_VALUE_ENUM.PROJECT_LIST]?.length) {
      const difference = formik.values[FORM_VALUE_ENUM.PROJECT_LIST].reduce(
        (acc: number, item: any) => {
          const amount = Number(item.amount) || 0; // Приводим к числу, пустые строки считаем за 0
          return preciseCalculation(acc, '-', amount);
        },
        formik.values[FORM_VALUE_ENUM.AMOUNT],
      );
      return Number(difference.toFixed(6));
    }
  };

  const getDifferencePercentage = () => {
    const difference = formik.values[FORM_VALUE_ENUM.PROJECT_LIST].reduce(
      (acc: number, item: any) => {
        const amount = Number(item.percentage) || 0; // Приводим к числу, пустые строки считаем за 0
        return preciseCalculation(acc, '-', amount);
      },
      100,
    );
    return Number(difference.toFixed(6));
  };

  const countPercentage = (e: any) => {
    formik.handleChange(e);
    const value = parseFloat(e.target.value) || 0; // Преобразуем в число, пустые строки считаем за 0
    const totalAmount = formik.values[FORM_VALUE_ENUM.AMOUNT];
    const percentage =
      totalAmount > 0 ? preciseCalculation(value, '/', totalAmount) * 100 : 0;

    formik.setFieldValue(
      e.target.name.replace('amount', 'percentage'),
      Number(percentage.toFixed(6)), // Округляем до 5 знаков
    );
  };

  const countAmount = (e: any) => {
    formik.handleChange(e);
    const value = parseFloat(e.target.value) || 0; // Преобразуем в число, пустые строки считаем за 0
    const totalAmount = formik.values[FORM_VALUE_ENUM.AMOUNT];
    const amount =
      totalAmount > 0 ? preciseCalculation(totalAmount, '*', value) / 100 : 0;

    formik.setFieldValue(
      e.target.name.replace('percentage', 'amount'),
      Number(amount.toFixed(6)), // Округляем до 5 знаков
    );
  };

  const updateTotalAmount = (e: any) => {
    formik.handleChange(e); // Сначала обновляем значение общего amount в formik
    const newTotalAmount = parseFloat(e.target.value) || 0; // Преобразуем в число, пустые строки считаем за 0

    // Пересчитываем все значения amount на основе percentage
    const updatedProjectList = formik.values[FORM_VALUE_ENUM.PROJECT_LIST]?.map(
      (item: any) => {
        const percentage = parseFloat(item.percentage) || 0; // Получаем значение процента
        const amount = preciseCalculation(
          preciseCalculation(newTotalAmount, '*', percentage),
          '/',
          100,
        ); // Пересчитываем amount
        return {
          ...item,
          amount: Number(amount.toFixed(6)) || '', // Обновляем значение amount, пустое значение оставляем пустым
        };
      },
    );

    // Обновляем projectList с пересчитанными значениями
    formik.setFieldValue(FORM_VALUE_ENUM.PROJECT_LIST, updatedProjectList);
  };

  return (
    <GridElem spacing={6}>
      <FormElem onSubmit={formik.handleSubmit}>
        {isLoading && <LoaderElem />}
        <ContentContainerElem spacing={5}>
          {isAdmin && (
            <DoubleContainerElem>
              <SelectPreloadContainer
                name={FORM_VALUE_ENUM.DEPARTMENT}
                action={getProjectList}
                convert={convertProjectListToSelect}
                formik={formik}
                title="EXPENDITURE.FORM.DEPARTMENT.TITLE"
                onChange={setFieldValue}
                errorMessage={getFieldError(FORM_VALUE_ENUM.DEPARTMENT)}
                error={isFieldError(FORM_VALUE_ENUM.DEPARTMENT)}
                value={getFieldValue(FORM_VALUE_ENUM.DEPARTMENT)}
                dynamic
                required
                placeholder="EXPENDITURE.FORM.DEPARTMENT.PLACEHOLDER"
              />

              <SelectPreloadContainer
                name={FORM_VALUE_ENUM.RESPONSIBLE}
                action={getAllUserList}
                convert={convertUserListToSelect}
                formik={formik}
                title="EXPENDITURE.FORM.RESPONSIBLE.TITLE"
                onChange={setFieldValue}
                errorMessage={getFieldError(FORM_VALUE_ENUM.RESPONSIBLE)}
                error={isFieldError(FORM_VALUE_ENUM.RESPONSIBLE)}
                value={getFieldValue(FORM_VALUE_ENUM.RESPONSIBLE)}
                required
                dynamic
                placeholder="EXPENDITURE.FORM.RESPONSIBLE.PLACEHOLDER"
              />
            </DoubleContainerElem>
          )}

          <DoubleContainerElem>
            <SelectElem
              name={FORM_VALUE_ENUM.BILL}
              onChange={setFieldValue}
              options={EXPENDITURE_BILL_OPTION_LIST}
              title="EXPENDITURE.FORM.BILL.TITLE"
              errorMessage={getFieldError(FORM_VALUE_ENUM.BILL)}
              error={isFieldError(FORM_VALUE_ENUM.BILL)}
              value={setValue(
                EXPENDITURE_BILL_OPTION_LIST,
                FORM_VALUE_ENUM.BILL,
              )}
              required
              placeholder="EXPENDITURE.FORM.BILL.PLACEHOLDER"
            />

            <FieldDataTimeInputElem
              name={FORM_VALUE_ENUM.DEADLINE}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="EXPENDITURE.FORM.DEADLINE.TITLE"
              value={getFieldValue(FORM_VALUE_ENUM.DEADLINE)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.DEADLINE)}
              error={isFieldError(FORM_VALUE_ENUM.DEADLINE)}
              format="YYYY-MM-DD"
              showTime={false}
              required
              placeholder="EXPENDITURE.FORM.DEADLINE.PLACEHOLDER"
            />
          </DoubleContainerElem>
          <DoubleContainerElem>
            <SelectPreloadContainer
              formik={formik}
              name={FORM_VALUE_ENUM.CATEGORY}
              value={getFieldValue(FORM_VALUE_ENUM.CATEGORY)}
              onChange={setFieldValue}
              action={getCategoryExpenseList}
              convert={convertCategoryToSelect}
              title="EXPENDITURE.FORM.CATEGORY.PLACEHOLDER"
              placeholder="EXPENDITURE.FORM.CATEGORY.PLACEHOLDER"
              errorMessage={getFieldError(FORM_VALUE_ENUM.CATEGORY)}
              error={isFieldError(FORM_VALUE_ENUM.CATEGORY)}
              param={uniqueNumber}
              dynamic
            />
          </DoubleContainerElem>

          <DoubleContainerElem>
            <FieldTextAreaElem
              name={FORM_VALUE_ENUM.REQUISITES}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="EXPENDITURE.FORM.REQUISITES.TITLE"
              value={getFieldValue(FORM_VALUE_ENUM.REQUISITES)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.REQUISITES)}
              error={isFieldError(FORM_VALUE_ENUM.REQUISITES)}
              required
            />
            <FieldTextAreaElem
              name={FORM_VALUE_ENUM.DESCRIPTION}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="EXPENDITURE.FORM.DESCRIPTION.TITLE"
              value={getFieldValue(FORM_VALUE_ENUM.DESCRIPTION)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
              error={isFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
              required
            />
          </DoubleContainerElem>
          <DoubleContainerElem>
            <GridElem spacing={2}>
              <TextElem
                tid="EXPENDITURE.FORM.COMMENT_FILE.TITLE"
                color="textPrimary"
              />
              <FileItemUploadContainer
                onSuccess={onSuccessUpload}
                name={FORM_VALUE_ENUM.COMMENT_FILE}
                type="file"
              />
            </GridElem>
          </DoubleContainerElem>

          <DoubleContainerElem>
            <GridElem>
              <FieldNumberElem
                name={FORM_VALUE_ENUM.AMOUNT}
                onChange={updateTotalAmount}
                onBlur={formik.handleBlur}
                title="EXPENDITURE.FORM.AMOUNT.TITLE"
                placeholder="EXPENDITURE.FORM.AMOUNT.PLACEHOLDER"
                value={getFieldValue(FORM_VALUE_ENUM.AMOUNT)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.AMOUNT)}
                error={isFieldError(FORM_VALUE_ENUM.AMOUNT)}
                required
              />
              {userData.isLoading ? (
                <SkeletonData />
              ) : (
                <>
                  {checkBudget ? (
                    <TextElem
                      tid="EXPENDITURE.PROMPT"
                      tvalue={{ value: budget }}
                      color="error"
                    />
                  ) : (
                    <TextElem
                      tid="EXPENDITURE.PROMPT"
                      tvalue={{ value: budget }}
                      color="active"
                    />
                  )}
                </>
              )}
            </GridElem>

            <SelectElem
              name={FORM_VALUE_ENUM.VALUTE}
              onChange={setFieldValue}
              options={EXPENDITURE_VALUTE_OPTION_LIST}
              title="EXPENDITURE.FORM.VALUTE.TITLE"
              errorMessage={getFieldError(FORM_VALUE_ENUM.VALUTE)}
              error={isFieldError(FORM_VALUE_ENUM.VALUTE)}
              value={setValue(
                EXPENDITURE_VALUTE_OPTION_LIST,
                FORM_VALUE_ENUM.VALUTE,
              )}
              required
              placeholder="EXPENDITURE.FORM.VALUTE.PLACEHOLDER"
            />
          </DoubleContainerElem>

          <TextElem
            tid="EXPENDITURE.TITLE.PAYMENT"
            type="semi-bold"
            size="main"
          />

          {!formik.values.projectList.length && (
            <DoubleContainerElem style={{ alignItems: 'start' }}>
              <SelectPreloadContainer
                formik={formik}
                name={FORM_VALUE_ENUM.PROJECT}
                value={getFieldValue(FORM_VALUE_ENUM.PROJECT)}
                onChange={setFieldValue}
                action={getExpenditureProjectList}
                convert={convertExpenditureListToSelect}
                title="EXPENDITURE.CREATE.PROJECT.TITLE"
                placeholder="EXPENDITURE.CREATE.PROJECT.PLACEHOLDER"
                errorMessage={getFieldError(FORM_VALUE_ENUM.PROJECT)}
                error={isFieldError(FORM_VALUE_ENUM.PROJECT)}
                dynamic
                required
              />
              <ButtonElem
                fill="outline"
                type="button"
                tid="EXPENDITURE.CREATE.PROJECT.ADD"
                iconLeft={
                  theme.type === THEME_ENUM.LIGHT ? addIconLight : addIcon
                }
                style={{ marginTop: '26px' }}
                onClick={() =>
                  formik.setFieldValue(FORM_VALUE_ENUM.PROJECT_LIST, [
                    {
                      id: '',
                      amount: 0,
                      percentage: 0,
                    },
                  ])
                }
              />
            </DoubleContainerElem>
          )}

          {!!formik.values.projectList.length && (
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <React.Fragment>
                  <FieldArray
                    name="projectList"
                    render={(arrayHelpers) => (
                      <GridElem spacing={6}>
                        {formik.values.projectList?.map(
                          (projectList: any, index: number) => {
                            const deleteProjectList = () => {
                              // if (formik.values.projectList.length > 1) {
                              arrayHelpers.remove(index);
                              // }
                            };
                            return (
                              <GridElem spacing={5}>
                                <ProjectListFieldContainer key={index}>
                                  <SelectPreloadContainer
                                    formik={formik}
                                    name={`${FORM_VALUE_ENUM.PROJECT_LIST}.${index}.id`}
                                    onChange={setFieldValue}
                                    value={
                                      formik.values?.projectList?.[index]?.id
                                    }
                                    onBlur={() =>
                                      formik.setFieldTouched(
                                        `${FORM_VALUE_ENUM.PROJECT_LIST}[${index}].id`,
                                        true,
                                      )
                                    }
                                    action={getExpenditureProjectList}
                                    convert={convertExpenditureListToSelect}
                                    title="EXPENDITURE.CREATE.PROJECT_LIST.TITLE"
                                    errorMessage={
                                      formik.touched?.projectList?.[index]
                                        ?.id &&
                                      formik.errors?.projectList?.[index]?.id
                                    }
                                    error={
                                      formik.touched?.projectList?.[index]
                                        ?.id &&
                                      formik.errors?.projectList?.[index]?.id
                                    }
                                    dynamic
                                    required
                                  />
                                  <FieldNumberElem
                                    name={`${FORM_VALUE_ENUM.PROJECT_LIST}.${index}.amount`}
                                    onChange={countPercentage}
                                    onBlur={formik.handleBlur}
                                    title="EXPENDITURE.CREATE.PROJECT_LIST.AMOUNT"
                                    value={
                                      formik.values.projectList[index].amount
                                    }
                                    errorMessage={
                                      formik.touched?.projectList?.[index]
                                        ?.amount &&
                                      formik.errors?.projectList?.[index]
                                        ?.amount
                                    }
                                    error={
                                      formik.touched?.projectList?.[index]
                                        ?.amount &&
                                      formik.errors?.projectList?.[index]
                                        ?.amount
                                    }
                                    required
                                  />

                                  <ProjectListControlContainer>
                                    <FieldNumberElem
                                      name={`${FORM_VALUE_ENUM.PROJECT_LIST}.${index}.percentage`}
                                      onChange={countAmount}
                                      onBlur={formik.handleBlur}
                                      title="EXPENDITURE.CREATE.PROJECT_LIST.PERCENTAGE"
                                      value={
                                        formik.values.projectList[index]
                                          .percentage
                                      }
                                      errorMessage={
                                        formik.touched?.projectList?.[index]
                                          ?.percentage &&
                                        formik.errors?.projectList?.[index]
                                          ?.percentage
                                      }
                                      error={
                                        formik.touched?.projectList?.[index]
                                          ?.percentage &&
                                        formik.errors?.projectList?.[index]
                                          ?.percentage
                                      }
                                      required
                                    />
                                    <ButtonElem
                                      type="button"
                                      iconLeft={CloseIcon}
                                      color="backgroundThird"
                                      onClick={deleteProjectList}
                                      style={{
                                        minWidth: '46px',
                                        width: '46px',
                                        padding: '15px',
                                        marginTop: '26px',
                                      }}
                                    />
                                  </ProjectListControlContainer>
                                </ProjectListFieldContainer>
                                <DividerElem />
                              </GridElem>
                            );
                          },
                        )}
                        <ProjectListFieldContainer
                          style={{ paddingRight: '38px' }}
                        >
                          <TextElem
                            tid="EXPENDITURE.CREATE.PROJECT_LIST.OUT"
                            color={getDifferenceAmount() ? 'active' : 'default'}
                          />
                          <TextElem
                            color={getDifferenceAmount() ? 'active' : 'default'}
                          >
                            {getDifferenceAmount()}
                          </TextElem>
                          <TextElem
                            color={getDifferenceAmount() ? 'active' : 'default'}
                          >
                            {getDifferencePercentage()}
                          </TextElem>
                        </ProjectListFieldContainer>

                        <ButtonElem
                          type="button"
                          onClick={() => {
                            arrayHelpers.push({
                              id: '',
                              amount: 0,
                              percentage: 0,
                            });
                            formik.setFieldValue(FORM_VALUE_ENUM.PROJECT, '');
                          }}
                          tid="EXPENDITURE.CREATE.PROJECT_LIST.ADD"
                          fill="outline"
                          iconLeft={
                            theme.type === THEME_ENUM.LIGHT
                              ? addIconLight
                              : addIcon
                          }
                          // disabled={isSubmitDisabled()}
                        />
                        {!!getDifferenceAmount() && (
                          <TextElem
                            tid="EXPENDITURE.CREATE.PROJECT_LIST.ERROR"
                            color="error"
                          />
                        )}
                      </GridElem>
                    )}
                  />
                </React.Fragment>
              </form>
            </FormikProvider>
          )}

          <ButtonElem
            type="submit"
            tid="EXPENDITURE.FORM.CREATE"
            fill="solid"
            color="success"
            disabled={isSubmitDisabled() || getDifferenceAmount()}
          />

          {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
          {isSuccess && <AlertActionElem type="success" tid="Success" />}
          {isLoading && <LoaderElem />}
        </ContentContainerElem>
      </FormElem>
    </GridElem>
  );
};

const SkeletonData = styled(IonSkeletonText)`
  height: 14px;
  margin: 0;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  opacity: ${({ theme }) => theme.value[VALUE_OPACITY_ENUM.SKELETON]};
`;

const ProjectListControlContainer = styled(GridElem)`
  grid-template-columns: auto 46px;
  column-gap: ${Spacing(2)};
`;

const ProjectListFieldContainer = styled(GridElem)`
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: ${Spacing(2)};
`;

export const DeleteIconStyled = styled(DeleteIcon)`
  path {
    transition: all 0.2s;
  }
`;
