import React from 'react';

import { Component } from './component';
import {
  email,
  name,
  number,
  numberPositiveMin,
  password,
  required,
  userName,
} from '../../lib/validation/service';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { updateUser } from './action';
import { USER_DATA } from '../../data/user/constant';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { USER_LIST_MODULE_NAME } from '../user-list';
import { convert } from '../user-item-create/convert';
import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
} from '../user-item-create/constant';

export const Container: React.FC<{
  data: USER_DATA;
  close: Function;
}> = ({ data, close }) => {
  const query = useQueryClient();

  const onSuccess = (d: any, values: any) => {
    close();
    query.invalidateQueries(USER_LIST_MODULE_NAME);
    formik.resetForm();
  };

  const action = useMutation(
    (values: FORM_VALUE_INTER) => updateUser(data.id, values),
    { onSuccess },
  );

  const config = {
    [FORM_VALUE_ENUM.EMAIL]: [required, email],
    [FORM_VALUE_ENUM.ROLE]: [required],
    [FORM_VALUE_ENUM.PROJECT]: [required],
    [FORM_VALUE_ENUM.NAME]: [required, userName],
    [FORM_VALUE_ENUM.PASSWORD]: [password],
    [FORM_VALUE_ENUM.TELEGRAM_ID]: [number, numberPositiveMin(0)],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const initialValues = {
    [FORM_VALUE_ENUM.EMAIL]: data.email,
    [FORM_VALUE_ENUM.NAME]: data.name,
    [FORM_VALUE_ENUM.PASSWORD]: '',
    [FORM_VALUE_ENUM.ROLE]: data.role,
    [FORM_VALUE_ENUM.TELEGRAM_ID]: data.telegramId,
    [FORM_VALUE_ENUM.PROJECT]: data.project?.id,
  };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: (values: FORM_VALUE_INTER) => {
      return action.mutate(convert(values));
    },
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }

    if (action.isSuccess) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) =>
    formik.setFieldValue(name, e.value);

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };

  return (
    <Component
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      formik={formik}
      isError={isError()}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      errorMessage={getErrorMessage()}
      close={close}
      setFieldValue={setFieldValue}
      setValue={setValue}
    />
  );
};
