import { i18n } from '../../lib/lang';
import { COLOR_ENUM } from '../../theme/color';
import { FILE_ITEM_DATA_RAW } from '../file/constant';
import { PROJECT_ITEM_DATA_RAW } from '../project/constant';
import { USER_DATA } from '../user/constant';
import { CURRENCY_TYPE } from '../valute/constant';

export const API = {
  PROJECT_LIST: {
    TYPE: 'GET',
    URL: 'expenditure/project-list',
  },

  ACCOUNT_LIST: {
    TYPE: 'GET',
    URL: 'expenditure/account-list',
  },

  CATEGORY_EXPENSE_LIST: {
    TYPE: 'GET',
    URL: (arg?: string) =>
      `expenditure/category-expense-list?&departmant=${arg}`,
  },
  COUNTERPARTY_LIST: {
    TYPE: 'GET',
    URL: 'expenditure/counterparty-list',
  },
};

export enum EXPENDITURE_TYPE {
  EXPENSE = 'EXPENSE',
  TRANSFER = 'TRANSFER',
}

export const EXPENDITURE_TYPE_OPTION_LIST = [
  {
    value: EXPENDITURE_TYPE.EXPENSE,
    label: `${i18n.t('EXPENDITURE.TYPE.EXPENSE')}`,
  },
  {
    value: EXPENDITURE_TYPE.TRANSFER,
    label: `${i18n.t('EXPENDITURE.TYPE.TRANSFER')}`,
  },
];

export enum EXPENDITURE_BILL {
  CARD = 'CARD',
  CRYPTO = 'CRYPTO',
  CASH = 'CASH',
  NON_CASH = 'NON_CASH',
  PAYPAL = 'PAYPAL',
}

export const EXPENDITURE_BILL_OPTION_LIST = [
  {
    value: EXPENDITURE_BILL.CARD,
    label: `${i18n.t('EXPENDITURE.BILL.CARD')}`,
  },
  {
    value: EXPENDITURE_BILL.CRYPTO,
    label: `${i18n.t('EXPENDITURE.BILL.CRYPTO')}`,
  },
  {
    value: EXPENDITURE_BILL.CASH,
    label: `${i18n.t('EXPENDITURE.BILL.CASH')}`,
  },
  {
    value: EXPENDITURE_BILL.NON_CASH,
    label: `${i18n.t('EXPENDITURE.BILL.NON_CASH')}`,
  },
  {
    value: EXPENDITURE_BILL.PAYPAL,
    label: `${i18n.t('EXPENDITURE.BILL.PAYPAL')}`,
  },
];

export enum EXPENDITURE_DEPARTMENT {
  MANAGEMENT = 'MANAGEMENT',
  TRAFFIC = 'TRAFFIC',
  FUNNEL = 'FUNNEL',
  TECHNICAL = 'TECHNICAL',
  ANALYTICS = 'ANALYTICS',
  SALES = 'SALES',
  PRODUCT = 'PRODUCT',
}

export const EXPENDITURE_DEPARTMENT_OPTION_LIST = [
  {
    value: EXPENDITURE_DEPARTMENT.MANAGEMENT,
    label: `${i18n.t('EXPENDITURE.DEPARTMENT.MANAGEMENT')}`,
  },
  {
    value: EXPENDITURE_DEPARTMENT.TRAFFIC,
    label: `${i18n.t('EXPENDITURE.DEPARTMENT.TRAFFIC')}`,
  },
  {
    value: EXPENDITURE_DEPARTMENT.FUNNEL,
    label: `${i18n.t('EXPENDITURE.DEPARTMENT.FUNNEL')}`,
  },
  {
    value: EXPENDITURE_DEPARTMENT.TECHNICAL,
    label: `${i18n.t('EXPENDITURE.DEPARTMENT.TECHNICAL')}`,
  },
  {
    value: EXPENDITURE_DEPARTMENT.ANALYTICS,
    label: `${i18n.t('EXPENDITURE.DEPARTMENT.ANALYTICS')}`,
  },
  {
    value: EXPENDITURE_DEPARTMENT.SALES,
    label: `${i18n.t('EXPENDITURE.DEPARTMENT.SALES')}`,
  },
  {
    value: EXPENDITURE_DEPARTMENT.PRODUCT,
    label: `${i18n.t('EXPENDITURE.DEPARTMENT.PRODUCT')}`,
  },
];

export const EXPENDITURE_VALUTE_OPTION_LIST = [
  {
    value: CURRENCY_TYPE.USD,
    label: `${i18n.t('EXPENDITURE.VALUTE.USD')}`,
  },
  {
    value: CURRENCY_TYPE.EUR,
    label: `${i18n.t('EXPENDITURE.VALUTE.EUR')}`,
  },
  {
    value: CURRENCY_TYPE.UAH,
    label: `${i18n.t('EXPENDITURE.VALUTE.UAH')}`,
  },
  {
    value: CURRENCY_TYPE.PLN,
    label: `${i18n.t('EXPENDITURE.VALUTE.PLN')}`,
  },
  {
    value: CURRENCY_TYPE.USDT,
    label: `${i18n.t('EXPENDITURE.VALUTE.USDT')}`,
  },
  {
    value: CURRENCY_TYPE.KZT,
    label: `${i18n.t('EXPENDITURE.VALUTE.KZT')}`,
  },
  {
    value: CURRENCY_TYPE.AED,
    label: `${i18n.t('EXPENDITURE.VALUTE.AED')}`,
  },
];

export enum EXPENDITURE_STATUS {
  IN_PROGRESS = 'IN_PROGRESS',
  CANCEL = 'CANCEL',
  PAYEED = 'PAYEED',
}

export const EXPENDITURE_BILL_COLOR = {
  [EXPENDITURE_BILL.CARD]: COLOR_ENUM.SUCCESS,
  [EXPENDITURE_BILL.CASH]: COLOR_ENUM.ERROR,
  [EXPENDITURE_BILL.CRYPTO]: COLOR_ENUM.YELLOW,
  [EXPENDITURE_BILL.NON_CASH]: COLOR_ENUM.BLUE,
  [EXPENDITURE_BILL.PAYPAL]: COLOR_ENUM.YELLOW,
};

export const EXPENDITURE_STATUS_COLOR = {
  [EXPENDITURE_STATUS.IN_PROGRESS]: COLOR_ENUM.BLUE,
  [EXPENDITURE_STATUS.CANCEL]: COLOR_ENUM.ERROR,
  [EXPENDITURE_STATUS.PAYEED]: COLOR_ENUM.SUCCESS,
};

export const EXPENDITURE_STATUS_OPTION_LIST = [
  {
    value: EXPENDITURE_STATUS.IN_PROGRESS,
    label: `${i18n.t('EXPENDITURE.STATUS.IN_PROGRESS')}`,
  },
  {
    value: EXPENDITURE_STATUS.CANCEL,
    label: `${i18n.t('EXPENDITURE.STATUS.CANCEL')}`,
  },
  {
    value: EXPENDITURE_STATUS.PAYEED,
    label: `${i18n.t('EXPENDITURE.STATUS.PAYEED')}`,
  },
];

export interface EXPENDITURE_ITEM_DATA_RAW {
  id: string;

  description: string;

  requisites: string;

  createDate: string;

  deadLine: string;

  valute: CURRENCY_TYPE;

  amount: number;

  category: string;

  bill: EXPENDITURE_BILL;

  departmant: PROJECT_ITEM_DATA_RAW;

  status: EXPENDITURE_STATUS;

  responsible: USER_DATA;

  comment: string;

  confirmFile: FILE_ITEM_DATA_RAW;

  commentFile: FILE_ITEM_DATA_RAW;
  account: string;
  accountTo: string;
  type: EXPENDITURE_TYPE;
  projectList: {
    id: string;
    amount: number;
    amountGrn: number;
    percentage: number;
    operationId?: string;
  }[];
  project: string;
  amountGrn: number;
  exchangeRate: number;
  counterparty: string;
  executor: USER_DATA;
  paymentDate: string;
  paymentDateConfirm: string;
}

export interface EXPENDITURE_ITEM_LIST_DATA_RAW {
  list: EXPENDITURE_ITEM_DATA_RAW[];
  total: number;
}

export interface EXPENDITURE_ITEM_DATA extends EXPENDITURE_ITEM_DATA_RAW {
  isWarning: boolean;
}

export interface EXPENDITURE_ITEM_LIST_DATA {
  list: EXPENDITURE_ITEM_DATA[];
  isEmpty: boolean;
}
