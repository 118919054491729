import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import {
  PROJECT_ITEM_DATA_RAW,
  PROJECT_ITEM_DATA,
  PROJECT_ITEM_LIST_DATA_RAW,
  PROJECT_ITEM_LIST_DATA,
} from './constant';

export const convertProject = (
  project: PROJECT_ITEM_DATA_RAW,
): PROJECT_ITEM_DATA => {
  return {
    ...project,
    customName: `${project?.name} ${project?.uniqueNumber}`,
  };
};

export const convertProjectList = (
  projectList: PROJECT_ITEM_LIST_DATA_RAW,
): PROJECT_ITEM_LIST_DATA => {
  return {
    list: projectList.list?.map((project: PROJECT_ITEM_DATA_RAW) => {
      return convertProject(project);
    }),
    isEmpty: !projectList.list?.length,
  };
};

export const convertProjectListToSelect = (data: PROJECT_ITEM_LIST_DATA) => {
  const newList: SELECT_OPTION_ITEM_DATA[] = [];
  data.list.map((item: PROJECT_ITEM_DATA) => {
    newList.push({
      value: item.id,
      restBudget: item?.restBudget,
      uniqueNumber: item?.uniqueNumber,
      label: `${item?.name} ${item?.uniqueNumber}`,
    });
  });

  return newList;
};
