import { FORM_VALUE_ENUM, FORM_VALUE_INTER } from './constant';

export const convert = (data: FORM_VALUE_INTER) => {
  return {
    ...data,
    [FORM_VALUE_ENUM.DESCRIPTION]: data[FORM_VALUE_ENUM.DESCRIPTION]?.trim(),
    [FORM_VALUE_ENUM.REQUISITES]: data[FORM_VALUE_ENUM.REQUISITES]?.trim(),
    [FORM_VALUE_ENUM.COMMENT]: data[FORM_VALUE_ENUM.COMMENT]?.trim(),
  };
};
