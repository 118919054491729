import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
} from './constant';

import { Component } from './component';
import {
  number,
  numberPositiveMin,
  required,
  requiredIfArray,
  requiredIfEqual,
} from '../../lib/validation/service';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { useMutation, useQueryClient } from 'react-query';
import { action as fetch } from './action';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import {
  EXPENDITURE_ITEM_DATA,
  EXPENDITURE_STATUS,
  EXPENDITURE_TYPE,
} from '../../data/expenditure/constant';
import { EXPENDITURE_LIST_MODULE_NAME } from '../expenditure-list';
import { convert } from './convert';
import * as Yup from 'yup';
import { i18n } from '../../lib/lang';
import { CURRENCY_TYPE } from '../../data/valute/constant';

export const Container: React.FC<{
  data: EXPENDITURE_ITEM_DATA;
  close: any;
  exchangeRate: any;
}> = ({ data, close, exchangeRate }) => {
  const query = useQueryClient();

  const [budget, setBudget] = useState(data?.departmant?.restBudget);
  const [uniqueNumber, setUniqueNumber] = useState(
    data?.departmant?.uniqueNumber,
  );

  const onSuccess = (d: any, values: any) => {
    query.invalidateQueries(EXPENDITURE_LIST_MODULE_NAME);
    formik.resetForm();
    close();
  };

  const action = useMutation(
    (values: FORM_VALUE_INTER) => fetch(values, data.id),
    {
      onSuccess,
    },
  );

  const config = {
    [FORM_VALUE_ENUM.DESCRIPTION]: [required],
    [FORM_VALUE_ENUM.REQUISITES]: [required],
    [FORM_VALUE_ENUM.DEADLINE]: [required],
    [FORM_VALUE_ENUM.VALUTE]: [required],
    [FORM_VALUE_ENUM.AMOUNT]: [required, number, numberPositiveMin(0)],
    [FORM_VALUE_ENUM.CATEGORY]: [],
    [FORM_VALUE_ENUM.BILL]: [required],
    [FORM_VALUE_ENUM.DEPARTMENT]: [required],
    [FORM_VALUE_ENUM.RESPONSIBLE]: [required],
    [FORM_VALUE_ENUM.STATUS]: [required],
    [FORM_VALUE_ENUM.DATE]: [
      requiredIfEqual(FORM_VALUE_ENUM.STATUS, EXPENDITURE_STATUS.PAYEED),
    ],
    [FORM_VALUE_ENUM.TYPE]: [
      requiredIfEqual(FORM_VALUE_ENUM.STATUS, EXPENDITURE_STATUS.PAYEED),
    ],
    [FORM_VALUE_ENUM.ACCOUNT]: [
      requiredIfEqual(FORM_VALUE_ENUM.STATUS, EXPENDITURE_STATUS.PAYEED),
    ],
    [FORM_VALUE_ENUM.ACCOUNT_TO]: [],
    [FORM_VALUE_ENUM.AMOUNT_GRN]: [
      requiredIfEqual(FORM_VALUE_ENUM.STATUS, EXPENDITURE_STATUS.PAYEED),
      number,
      numberPositiveMin(0),
    ],
    [FORM_VALUE_ENUM.EXCHANGE_RATE]: [
      requiredIfEqual(FORM_VALUE_ENUM.STATUS, EXPENDITURE_STATUS.PAYEED),
      number,
      numberPositiveMin(0),
    ],
    [FORM_VALUE_ENUM.COMMENT_FILE]: [],
    [FORM_VALUE_ENUM.CONFIRM_FILE]: [],
    [FORM_VALUE_ENUM.COMMENT]: [],
    [FORM_VALUE_ENUM.COUNTERPARTY]: [
      requiredIfEqual(FORM_VALUE_ENUM.STATUS, EXPENDITURE_STATUS.PAYEED),
    ],
    [FORM_VALUE_ENUM.EXECUTER]: [],
    [FORM_VALUE_ENUM.PAYMENT_DATE_CONFIRM]: [
      requiredIfEqual(FORM_VALUE_ENUM.STATUS, EXPENDITURE_STATUS.PAYEED),
    ],
    [FORM_VALUE_ENUM.PAYMENT_DATE]: [],
    [FORM_VALUE_ENUM.PROJECT]: [requiredIfArray(FORM_VALUE_ENUM.PROJECT_LIST)],
  };
  const validate = (values: FormikValues) => validation(values, config);

  const getAmountGrn = () => {
    if (data.amountGrn && data.amountGrn != 0)
      return Number(Number(data.amountGrn).toFixed(6));
    if (data.valute === CURRENCY_TYPE.UAH)
      return Number(Number(data.amount).toFixed(6));

    if (data.exchangeRate)
      return Number(Number(data.exchangeRate * data.amount).toFixed(6));
    return 0;
  };

  const getExchangeRate = () => {
    if (data.exchangeRate && data.exchangeRate != 0) {
      return data.exchangeRate;
    }
    if (data.valute === CURRENCY_TYPE.UAH) {
      return 1;
    }
    return 0;
  };

  const checkBudget = () => {
    if (
      formik.values[FORM_VALUE_ENUM.AMOUNT] &&
      formik.values[FORM_VALUE_ENUM.VALUTE]
    ) {
      if (
        exchangeRate[formik.values[FORM_VALUE_ENUM.VALUTE]] *
          formik.values[FORM_VALUE_ENUM.AMOUNT] >
        Number(budget) +
          exchangeRate[data[FORM_VALUE_ENUM.VALUTE]] *
            Number(data[FORM_VALUE_ENUM.AMOUNT])
      ) {
        return true;
      }
    }
    return false;
  };

  const initialValues = {
    [FORM_VALUE_ENUM.DESCRIPTION]: data.description,
    [FORM_VALUE_ENUM.REQUISITES]: data.requisites,
    [FORM_VALUE_ENUM.DEADLINE]: data.deadLine,
    [FORM_VALUE_ENUM.VALUTE]: data.valute,
    [FORM_VALUE_ENUM.AMOUNT]: Number(Number(data.amount).toFixed(6)),
    [FORM_VALUE_ENUM.CATEGORY]: data?.category,
    [FORM_VALUE_ENUM.BILL]: data.bill,
    [FORM_VALUE_ENUM.TYPE]: data.type,
    [FORM_VALUE_ENUM.DEPARTMENT]: data?.departmant?.id,
    [FORM_VALUE_ENUM.RESPONSIBLE]: data?.responsible?.id,
    [FORM_VALUE_ENUM.STATUS]: data.status,
    [FORM_VALUE_ENUM.DATE]: data.createDate,
    [FORM_VALUE_ENUM.COMMENT_FILE]: data?.commentFile?.id,
    [FORM_VALUE_ENUM.CONFIRM_FILE]: data?.confirmFile?.id,
    [FORM_VALUE_ENUM.COMMENT]: data?.comment,
    [FORM_VALUE_ENUM.ACCOUNT]: data?.account,
    [FORM_VALUE_ENUM.ACCOUNT_TO]: data?.accountTo,
    [FORM_VALUE_ENUM.PROJECT]: data?.project,
    [FORM_VALUE_ENUM.PROJECT_LIST]: data.projectList?.map((item) => {
      return { ...item, amountGrn: item.amountGrn ? item.amountGrn : 0 };
    }),
    [FORM_VALUE_ENUM.AMOUNT_GRN]: getAmountGrn(),
    [FORM_VALUE_ENUM.EXCHANGE_RATE]: getExchangeRate(),
    [FORM_VALUE_ENUM.COUNTERPARTY]: data?.counterparty,
    [FORM_VALUE_ENUM.EXECUTER]: data?.executor?.name,
    [FORM_VALUE_ENUM.PAYMENT_DATE]: data?.paymentDate,
    [FORM_VALUE_ENUM.PAYMENT_DATE_CONFIRM]: data?.paymentDateConfirm
      ? data?.paymentDateConfirm
      : new Date(new Date().setUTCHours(0, 0, 0, 0)).toISOString(),
  };

  const validationSchema = Yup.object().shape({
    projectList: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
        amount: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
        amountGrn: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
        percentage: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
      }),
    ),
  });

  const formik: FormikValues = useFormik({
    initialValues,
    validationSchema,
    validate,
    onSubmit: (values: FORM_VALUE_INTER) => {
      return action.mutate(convert(values));
    },
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = useMemo(() => {
    return (
      !formik.isValid ||
      (!formik.values[FORM_VALUE_ENUM.PROJECT_LIST]?.length &&
        !formik.values[FORM_VALUE_ENUM.PROJECT]) ||
      checkBudget() ||
      !formik.dirty ||
      action.isLoading
    );
  }, [
    formik.isValid,
    formik.values,
    checkBudget,
    formik.dirty,
    action.isLoading,
  ]);
  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  console.log(formik.values);

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = useCallback(
    (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) => {
      formik.setFieldValue(name, e.value);
      if (e?.restBudget !== undefined && e?.restBudget !== budget) {
        setBudget(e.restBudget);
      }
      if (e?.uniqueNumber !== undefined && e?.uniqueNumber !== uniqueNumber) {
        setUniqueNumber(e.uniqueNumber);
      }
    },
    [budget, uniqueNumber, formik],
  );

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };

  const onSuccessUpload = (id: string, name: string) => {
    formik.setFieldValue(name, id);
  };

  useEffect(() => {
    console.log('useEffect AMOUNT');

    const newTotalAmount = formik.values[FORM_VALUE_ENUM.AMOUNT];
    const updatedProjectList = formik.values[FORM_VALUE_ENUM.PROJECT_LIST]?.map(
      (item: any) => {
        const percentage = parseFloat(item.percentage) || 0;
        const amount = (newTotalAmount * percentage) / 100;
        const amountGrn =
          (newTotalAmount *
            percentage *
            formik.values[FORM_VALUE_ENUM.EXCHANGE_RATE]) /
          100;

        return {
          ...item,
          amountGrn: Number(amountGrn.toFixed(6)) || '0',
          amount: Number(amount.toFixed(6)) || '0',
        };
      },
    );

    if (
      formik.values[FORM_VALUE_ENUM.EXCHANGE_RATE] &&
      Number(formik.values[FORM_VALUE_ENUM.AMOUNT_GRN]) !=
        Number(
          (
            formik.values[FORM_VALUE_ENUM.EXCHANGE_RATE] * newTotalAmount
          ).toFixed(6),
        ) &&
      formik.values[FORM_VALUE_ENUM.AMOUNT] !=
        formik.initialValues[FORM_VALUE_ENUM.AMOUNT]
    ) {
      formik.setFieldValue(
        FORM_VALUE_ENUM.AMOUNT_GRN,
        Number(
          (
            formik.values[FORM_VALUE_ENUM.EXCHANGE_RATE] * newTotalAmount
          ).toFixed(6),
        ),
      );
    }

    formik.setFieldValue(FORM_VALUE_ENUM.PROJECT_LIST, updatedProjectList);
  }, [formik.values[FORM_VALUE_ENUM.AMOUNT]]);

  useEffect(() => {
    console.log('useEffect EXCHANGE_RATE');
    const newExchangeRate = formik.values[FORM_VALUE_ENUM.EXCHANGE_RATE] || 0;
    const newTotalAmount = formik.values[FORM_VALUE_ENUM.AMOUNT];

    const updatedProjectList = formik.values[FORM_VALUE_ENUM.PROJECT_LIST]?.map(
      (item: any) => {
        const percentage = parseFloat(item.percentage) || 0;
        const amountGrn =
          (newTotalAmount *
            percentage *
            formik.values[FORM_VALUE_ENUM.EXCHANGE_RATE]) /
          100;

        return {
          ...item,
          amountGrn: Number(amountGrn.toFixed(6)) || '0',
        };
      },
    );

    formik.setFieldValue(FORM_VALUE_ENUM.PROJECT_LIST, updatedProjectList);

    if (
      formik.values[FORM_VALUE_ENUM.AMOUNT] &&
      Number(formik.values[FORM_VALUE_ENUM.AMOUNT_GRN]) !=
        Number(
          (formik.values[FORM_VALUE_ENUM.AMOUNT] * newExchangeRate).toFixed(6),
        ) &&
      formik.values[FORM_VALUE_ENUM.EXCHANGE_RATE] !=
        formik.initialValues[FORM_VALUE_ENUM.EXCHANGE_RATE]
    ) {
      console.log(
        'EXCHANGE_RATE old',
        Number(
          (formik.values[FORM_VALUE_ENUM.AMOUNT] * newExchangeRate).toFixed(6),
        ),
        formik.values[FORM_VALUE_ENUM.EXCHANGE_RATE],
        formik.values[FORM_VALUE_ENUM.AMOUNT_GRN],
        formik.initialValues,
      );

      formik.setFieldValue(
        FORM_VALUE_ENUM.AMOUNT_GRN,
        Number(
          (formik.values[FORM_VALUE_ENUM.AMOUNT] * newExchangeRate).toFixed(6),
        ),
      );
    }
  }, [formik.values[FORM_VALUE_ENUM.EXCHANGE_RATE]]);

  useEffect(() => {
    console.log('useEffect AMOUNT_GRN');

    const newGrnAmount = formik.values[FORM_VALUE_ENUM.AMOUNT_GRN] || 0;
    const newTotalAmount = formik.values[FORM_VALUE_ENUM.AMOUNT];

    const updatedProjectList = formik.values[FORM_VALUE_ENUM.PROJECT_LIST]?.map(
      (item: any) => {
        const percentage = parseFloat(item.percentage) || 0;
        const amountGrn =
          (newTotalAmount *
            percentage *
            formik.values[FORM_VALUE_ENUM.EXCHANGE_RATE]) /
          100;

        return {
          ...item,
          amountGrn: Number(amountGrn.toFixed(6)) || '0',
        };
      },
    );

    formik.setFieldValue(FORM_VALUE_ENUM.PROJECT_LIST, updatedProjectList);

    if (
      formik.values[FORM_VALUE_ENUM.AMOUNT] &&
      Number(formik.values[FORM_VALUE_ENUM.EXCHANGE_RATE]) !=
        Number(
          (newGrnAmount / formik.values[FORM_VALUE_ENUM.AMOUNT]).toFixed(6),
        ) &&
      formik.values[FORM_VALUE_ENUM.AMOUNT_GRN] !=
        formik.initialValues[FORM_VALUE_ENUM.AMOUNT_GRN]
    ) {
      console.log(
        'EXCHANGE_RATE new',
        Number(
          (newGrnAmount / formik.values[FORM_VALUE_ENUM.AMOUNT]).toFixed(6),
        ),
      );
      formik.setFieldValue(
        FORM_VALUE_ENUM.EXCHANGE_RATE,
        Number(
          (newGrnAmount / formik.values[FORM_VALUE_ENUM.AMOUNT]).toFixed(6),
        ),
      );
    }
  }, [formik.values[FORM_VALUE_ENUM.AMOUNT_GRN]]);

  return (
    <Component
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      formik={formik}
      isError={isError()}
      isLoading={isLoading()}
      errorMessage={getErrorMessage()}
      setFieldValue={setFieldValue}
      setValue={setValue}
      close={close}
      isSuccess={isSuccess()}
      onSuccessUpload={onSuccessUpload}
      data={data}
      budget={budget}
      checkBudget={checkBudget()}
      uniqueNumber={uniqueNumber}
    />
  );
};
