import {
  EXPENDITURE_ITEM_LIST_DATA,
  EXPENDITURE_ITEM_LIST_DATA_RAW,
  EXPENDITURE_STATUS,
} from '../../data/expenditure/constant';

export const MODULE_NAME = 'PERSON_LIST_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  TYPE: 'GET',
  URL: (params: string) => `/expenditure/${params}`,
};

export interface CALENDAR {
  startDate: any;
  endDate: any;
  key?: string;
}

export const STATUS_TAB = [
  {
    tid: 'EXPENDITURE.STATUS.ALL',
    value: '',
  },
  {
    tid: 'EXPENDITURE.STATUS.IN_PROGRESS',
    value: EXPENDITURE_STATUS.IN_PROGRESS,
  },
  {
    tid: 'EXPENDITURE.STATUS.PAYEED',
    value: EXPENDITURE_STATUS.PAYEED,
  },
  {
    tid: 'EXPENDITURE.STATUS.CANCEL',
    value: EXPENDITURE_STATUS.CANCEL,
  },
];

export interface ACTION_RESPONSE_INTER extends EXPENDITURE_ITEM_LIST_DATA_RAW {}

export interface DATA extends EXPENDITURE_ITEM_LIST_DATA {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}
