import React from 'react';

import { FieldArray, FormikProvider } from 'formik';

import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import styled from 'styled-components';
import { i18n } from '../../lib/lang';

import { DoubleContainerElem } from '../../common/double-container';

import { FieldDataTimeInputElem } from '../../common/field-datetime-input';
import {
  EXPENDITURE_BILL_OPTION_LIST,
  EXPENDITURE_TYPE_OPTION_LIST,
  EXPENDITURE_DEPARTMENT_OPTION_LIST,
  EXPENDITURE_STATUS_OPTION_LIST,
  EXPENDITURE_VALUTE_OPTION_LIST,
  EXPENDITURE_ITEM_DATA,
  EXPENDITURE_TYPE,
} from '../../data/expenditure/constant';
import { SelectElem } from '../../common/select';
import { getAllUserList } from '../../data/user/action';
import { convertUserListToSelect } from '../../data/user/convert';
import { SelectPreloadContainer } from '../select-preload';
import closeIcon from '../../asset/svg/common/close.svg';
import { FieldTextAreaElem } from '../../common/field-text-area';
import { TextElem } from '../../common/text';
import { FileItemUploadContainer } from '../file-item-upload';
import { FORM_VALUE_ENUM } from './constant';
import { DividerElem } from '../../common/divider';
import {
  getAccountList,
  getCategoryExpenseList,
  getCounterpartyList,
  getExpenditureProjectList,
} from '../../data/expenditure/action';
import { useSelector } from '../../lib/store';
import { THEME_MODULE_NAME } from '../../data/theme';
import { THEME_ENUM } from '../../data/theme/constant';
import CloseIcon from '../../asset/svg/common/close.svg';
import addIcon from '../../asset/svg/button/plus-light.svg';
import addIconLight from '../../asset/svg/button/plus.svg';
import { Spacing } from '../../theme';
import { ReactComponent as DeleteIcon } from '../../asset/svg/common/close.svg';
import { CURRENCY_TYPE } from '../../data/valute/constant';
import { getProjectList } from '../../data/project/action';
import { convertProjectListToSelect } from '../../data/project/convert';
import {
  convertCategoryToSelect,
  convertExpenditureListToSelect,
} from '../../data/expenditure/convert';
import { FieldNumberElem } from '../../common/field-number';
import { preciseCalculation } from '../../lib/util/calculate';

export const Component: React.FC<{
  formik: any;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: boolean;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  setFieldValue: Function;
  setValue: Function;
  close: any;
  onSuccessUpload: Function;
  data?: EXPENDITURE_ITEM_DATA;
  budget: any;
  checkBudget: boolean;
  uniqueNumber: number;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isError,
  errorMessage,
  setFieldValue,
  setValue,
  close,
  isSuccess,
  onSuccessUpload,
  data,
  budget,
  checkBudget,
  uniqueNumber,
}) => {
  const { theme } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));

  const getDifferenceAmount = () => {
    if (formik.values[FORM_VALUE_ENUM.PROJECT_LIST]?.length) {
      const difference = formik.values[FORM_VALUE_ENUM.PROJECT_LIST].reduce(
        (acc: number, item: any) => {
          const amount = Number(item.amount) || 0; // Приводим к числу, пустые строки считаем за 0
          return preciseCalculation(acc, '-', amount);
        },
        formik.values[FORM_VALUE_ENUM.AMOUNT],
      );
      return Number(difference.toFixed(6));
    }
  };

  const getDifferenceAmountGrn = () => {
    const difference = formik.values[FORM_VALUE_ENUM.PROJECT_LIST].reduce(
      (acc: number, item: any) => {
        const amount = Number(item.amountGrn) || 0; // Приводим к числу, пустые строки считаем за 0
        return Number(preciseCalculation(acc, '-', amount).toFixed(6));
      },
      formik.values[FORM_VALUE_ENUM.AMOUNT_GRN],
    );
    return Number(difference.toFixed(6));
  };

  const getDifferencePercentage = () => {
    const difference = formik.values[FORM_VALUE_ENUM.PROJECT_LIST].reduce(
      (acc: number, item: any) => {
        const amount = Number(item.percentage) || 0; // Приводим к числу, пустые строки считаем за 0
        return preciseCalculation(acc, '-', amount);
      },
      100,
    );
    return Number(difference.toFixed(6));
  };

  const countPercentage = (e: any) => {
    formik.handleChange(e);
    const value = parseFloat(e.target.value) || 0;
    const totalAmount = formik.values[FORM_VALUE_ENUM.AMOUNT];
    const percentage =
      totalAmount > 0 ? preciseCalculation(value, '/', totalAmount) * 100 : 0;

    formik.setFieldValue(
      e.target.name.replace('amount', 'amountGrn'),
      Number(
        preciseCalculation(value, '*', formik.values?.exchangeRate).toFixed(6),
      ),
    );
    formik.setFieldValue(
      e.target.name.replace('amount', 'percentage'),
      Number(percentage.toFixed(6)),
    );
  };

  const countAmount = (e: any) => {
    formik.handleChange(e);
    const value = parseFloat(e.target.value) || 0;
    const totalAmount = formik.values[FORM_VALUE_ENUM.AMOUNT];
    const amount =
      totalAmount > 0 ? preciseCalculation(totalAmount, '*', value) / 100 : 0;

    formik.setFieldValue(
      e.target.name.replace('percentage', 'amountGrn'),
      Number(
        preciseCalculation(amount, '*', formik.values?.exchangeRate).toFixed(6),
      ),
    );

    formik.setFieldValue(
      e.target.name.replace('percentage', 'amount'),
      Number(amount.toFixed(6)),
    );
  };

  const countValute = (name: string, value: any) => {
    setFieldValue(name, value);
    if (value.value === CURRENCY_TYPE.UAH) {
      formik.setFieldValue(FORM_VALUE_ENUM.EXCHANGE_RATE, 1);
    }
  };

  return (
    <FormElemCustom onSubmit={formik.handleSubmit}>
      {isLoading && <LoaderElem />}
      <GridElem spacing={5}>
        <GridElem spacing={5}>
          <TitleContainer>
            <TextElem
              tid="EXPENDITURE.TITLE.GENERAl"
              type="semi-bold"
              size="main"
            />
            <Icon src={closeIcon} onClick={close} />
          </TitleContainer>
          <DoubleContainerElem>
            <SelectPreloadContainer
              name={FORM_VALUE_ENUM.DEPARTMENT}
              action={getProjectList}
              convert={convertProjectListToSelect}
              formik={formik}
              title="EXPENDITURE.FORM.DEPARTMENT.TITLE"
              onChange={setFieldValue}
              errorMessage={getFieldError(FORM_VALUE_ENUM.DEPARTMENT)}
              error={isFieldError(FORM_VALUE_ENUM.DEPARTMENT)}
              value={getFieldValue(FORM_VALUE_ENUM.DEPARTMENT)}
              placeholder=""
              dynamic
              required
            />

            <SelectPreloadContainer
              name={FORM_VALUE_ENUM.RESPONSIBLE}
              action={getAllUserList}
              convert={convertUserListToSelect}
              formik={formik}
              title="EXPENDITURE.FORM.RESPONSIBLE.TITLE"
              onChange={setFieldValue}
              errorMessage={getFieldError(FORM_VALUE_ENUM.RESPONSIBLE)}
              error={isFieldError(FORM_VALUE_ENUM.RESPONSIBLE)}
              value={getFieldValue(FORM_VALUE_ENUM.RESPONSIBLE)}
              dynamic
              required
            />
          </DoubleContainerElem>
          <DoubleContainerElem>
            <SelectElem
              name={FORM_VALUE_ENUM.TYPE}
              onChange={setFieldValue}
              options={EXPENDITURE_TYPE_OPTION_LIST}
              title="EXPENDITURE.FORM.TYPE.TITLE"
              placeholder="EXPENDITURE.FORM.TYPE.PLACEHOLDER"
              errorMessage={getFieldError(FORM_VALUE_ENUM.TYPE)}
              error={isFieldError(FORM_VALUE_ENUM.TYPE)}
              value={setValue(
                EXPENDITURE_TYPE_OPTION_LIST,
                FORM_VALUE_ENUM.TYPE,
              )}
              onBlur={formik.handleBlur}
              required
            />
            <SelectElem
              name={FORM_VALUE_ENUM.BILL}
              onChange={setFieldValue}
              options={EXPENDITURE_BILL_OPTION_LIST}
              title="EXPENDITURE.FORM.BILL.TITLE"
              errorMessage={getFieldError(FORM_VALUE_ENUM.BILL)}
              error={isFieldError(FORM_VALUE_ENUM.BILL)}
              value={setValue(
                EXPENDITURE_BILL_OPTION_LIST,
                FORM_VALUE_ENUM.BILL,
              )}
              required
            />
          </DoubleContainerElem>
          <DoubleContainerElem>
            <SelectPreloadContainer
              formik={formik}
              name={FORM_VALUE_ENUM.CATEGORY}
              value={getFieldValue(FORM_VALUE_ENUM.CATEGORY)}
              onChange={setFieldValue}
              action={getCategoryExpenseList}
              convert={convertCategoryToSelect}
              title="EXPENDITURE.FORM.CATEGORY.PLACEHOLDER"
              placeholder="EXPENDITURE.FORM.CATEGORY.PLACEHOLDER"
              errorMessage={getFieldError(FORM_VALUE_ENUM.CATEGORY)}
              error={isFieldError(FORM_VALUE_ENUM.CATEGORY)}
              param={uniqueNumber}
              dynamic
            />
            <SelectPreloadContainer
              formik={formik}
              name={FORM_VALUE_ENUM.ACCOUNT}
              value={getFieldValue(FORM_VALUE_ENUM.ACCOUNT)}
              onChange={setFieldValue}
              action={getAccountList}
              convert={convertExpenditureListToSelect}
              title="EXPENDITURE.FORM.ACCOUNT.TITLE"
              placeholder="EXPENDITURE.FORM.ACCOUNT.PLACEHOLDER"
              errorMessage={getFieldError(FORM_VALUE_ENUM.ACCOUNT)}
              error={isFieldError(FORM_VALUE_ENUM.ACCOUNT)}
              dynamic
              required
            />
            {formik.values[FORM_VALUE_ENUM.TYPE] ===
              EXPENDITURE_TYPE.TRANSFER && (
              <SelectPreloadContainer
                formik={formik}
                name={FORM_VALUE_ENUM.ACCOUNT_TO}
                value={getFieldValue(FORM_VALUE_ENUM.ACCOUNT_TO)}
                onChange={setFieldValue}
                action={getAccountList}
                convert={convertExpenditureListToSelect}
                title="EXPENDITURE.FORM.ACCOUNT_TO.TITLE"
                placeholder="EXPENDITURE.FORM.ACCOUNT_TO.PLACEHOLDER"
                errorMessage={getFieldError(FORM_VALUE_ENUM.ACCOUNT_TO)}
                error={isFieldError(FORM_VALUE_ENUM.ACCOUNT_TO)}
                dynamic
                required
              />
            )}
          </DoubleContainerElem>

          <DoubleContainerElem>
            <SelectPreloadContainer
              formik={formik}
              name={FORM_VALUE_ENUM.COUNTERPARTY}
              value={getFieldValue(FORM_VALUE_ENUM.COUNTERPARTY)}
              onChange={setFieldValue}
              action={getCounterpartyList}
              convert={convertExpenditureListToSelect}
              title="EXPENDITURE.FORM.COUNTERPARTY.TITLE"
              placeholder="EXPENDITURE.FORM.COUNTERPARTY.PLACEHOLDER"
              errorMessage={getFieldError(FORM_VALUE_ENUM.COUNTERPARTY)}
              error={isFieldError(FORM_VALUE_ENUM.COUNTERPARTY)}
              dynamic
              required
            />
            <FieldTextElem
              title="EXPENDITURE.FORM.EXECUTER.TITLE"
              placeholder="EXPENDITURE.FORM.EXECUTER.PLACEHOLDER"
              // disabled
              value={getFieldValue(FORM_VALUE_ENUM.EXECUTER)}
            />
          </DoubleContainerElem>
        </GridElem>
        <GridElem spacing={5}>
          <TextElem tid="EXPENDITURE.TITLE.DATE" type="semi-bold" size="main" />
          <DoubleContainerElem>
            <FieldDataTimeInputElem
              name={FORM_VALUE_ENUM.DATE}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="EXPENDITURE.FORM.DATE.TITLE"
              value={getFieldValue(FORM_VALUE_ENUM.DATE)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.DATE)}
              error={isFieldError(FORM_VALUE_ENUM.DATE)}
              format="YYYY-MM-DD"
              showTime={false}
              required
            />
            <FieldDataTimeInputElem
              name={FORM_VALUE_ENUM.DEADLINE}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="EXPENDITURE.FORM.DEADLINE.TITLE"
              value={getFieldValue(FORM_VALUE_ENUM.DEADLINE)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.DEADLINE)}
              error={isFieldError(FORM_VALUE_ENUM.DEADLINE)}
              format="YYYY-MM-DD"
              showTime={false}
              required
            />
          </DoubleContainerElem>
          <DoubleContainerElem>
            <FieldDataTimeInputElem
              title="EXPENDITURE.FORM.PAYMENT_DATE.TITLE"
              value={getFieldValue(FORM_VALUE_ENUM.PAYMENT_DATE)}
              format="YYYY-MM-DD"
              showTime={false}
              disabled
              placeholder="EXPENDITURE.FORM.PAYMENT_DATE.TITLE"
            />
            <FieldDataTimeInputElem
              name={FORM_VALUE_ENUM.PAYMENT_DATE_CONFIRM}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="EXPENDITURE.FORM.PAYMENT_DATE_CONFIRM.TITLE"
              // placeholder="EXPENDITURE.FORM.PAYMENT_DATE_CONFIRM.TITLE"
              value={getFieldValue(FORM_VALUE_ENUM.PAYMENT_DATE_CONFIRM)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.PAYMENT_DATE_CONFIRM)}
              error={isFieldError(FORM_VALUE_ENUM.PAYMENT_DATE_CONFIRM)}
              format="YYYY-MM-DD"
              showTime={false}
              required
            />
          </DoubleContainerElem>
        </GridElem>
        <GridElem spacing={5}>
          <TextElem
            tid="EXPENDITURE.TITLE.ADDITIONAL"
            type="semi-bold"
            size="main"
          />
          <DoubleContainerElem>
            <FieldTextAreaElem
              name={FORM_VALUE_ENUM.REQUISITES}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="EXPENDITURE.FORM.REQUISITES.TITLE"
              value={getFieldValue(FORM_VALUE_ENUM.REQUISITES)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.REQUISITES)}
              error={isFieldError(FORM_VALUE_ENUM.REQUISITES)}
              required
            />
            <FieldTextAreaElem
              name={FORM_VALUE_ENUM.DESCRIPTION}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              title="EXPENDITURE.FORM.DESCRIPTION.TITLE"
              value={getFieldValue(FORM_VALUE_ENUM.DESCRIPTION)}
              errorMessage={getFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
              error={isFieldError(FORM_VALUE_ENUM.DESCRIPTION)}
              required
            />
          </DoubleContainerElem>
          <DoubleContainerElem>
            <GridElem spacing={2}>
              <TextElem
                tid="EXPENDITURE.FORM.COMMENT_FILE.TITLE"
                color="textPrimary"
              />
              <FileItemUploadContainer
                onSuccess={onSuccessUpload}
                defaultValue={data?.commentFile?.url}
                name={FORM_VALUE_ENUM.COMMENT_FILE}
                type="file"
              />
            </GridElem>
            <GridElem spacing={2}>
              <TextElem
                tid="EXPENDITURE.FORM.CONFIRM_FILE.TITLE"
                color="textPrimary"
              />
              <FileItemUploadContainer
                onSuccess={onSuccessUpload}
                defaultValue={data?.confirmFile?.url}
                name={FORM_VALUE_ENUM.CONFIRM_FILE}
                type="file"
              />
            </GridElem>
          </DoubleContainerElem>
          <FieldTextElem
            name={FORM_VALUE_ENUM.COMMENT}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            title="EXPENDITURE.FORM.COMMENT.TITLE"
            value={getFieldValue(FORM_VALUE_ENUM.COMMENT)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.COMMENT)}
            error={isFieldError(FORM_VALUE_ENUM.COMMENT)}
          />
        </GridElem>
        <GridElem spacing={5}>
          <TextElem
            tid="EXPENDITURE.TITLE.PAYMENT"
            type="semi-bold"
            size="main"
          />
          <GridElem>
            <ProjectListFieldContainer>
              <SelectElem
                name={FORM_VALUE_ENUM.VALUTE}
                onChange={countValute}
                options={EXPENDITURE_VALUTE_OPTION_LIST}
                title="EXPENDITURE.FORM.VALUTE.TITLE"
                errorMessage={getFieldError(FORM_VALUE_ENUM.VALUTE)}
                error={isFieldError(FORM_VALUE_ENUM.VALUTE)}
                value={setValue(
                  EXPENDITURE_VALUTE_OPTION_LIST,
                  FORM_VALUE_ENUM.VALUTE,
                )}
                required
              />
              <FieldNumberElem
                name={FORM_VALUE_ENUM.AMOUNT}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="EXPENDITURE.FORM.AMOUNT_ALL_VALUTE"
                value={getFieldValue(FORM_VALUE_ENUM.AMOUNT)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.AMOUNT)}
                error={isFieldError(FORM_VALUE_ENUM.AMOUNT)}
                required
              />

              <FieldNumberElem
                name={FORM_VALUE_ENUM.AMOUNT_GRN}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="EXPENDITURE.FORM.AMOUNT_ALL_GRN"
                value={getFieldValue(FORM_VALUE_ENUM.AMOUNT_GRN)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.AMOUNT_GRN)}
                error={isFieldError(FORM_VALUE_ENUM.AMOUNT_GRN)}
                required
              />

              <FieldNumberElem
                name={FORM_VALUE_ENUM.EXCHANGE_RATE}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="EXPENDITURE.FORM.EXCHANGE"
                value={getFieldValue(FORM_VALUE_ENUM.EXCHANGE_RATE)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.EXCHANGE_RATE)}
                error={isFieldError(FORM_VALUE_ENUM.EXCHANGE_RATE)}
                required
              />
            </ProjectListFieldContainer>
            <>
              {checkBudget ? (
                <TextElem
                  tid="EXPENDITURE.PROMPT"
                  tvalue={{ value: budget }}
                  color="error"
                />
              ) : (
                <TextElem
                  tid="EXPENDITURE.PROMPT"
                  tvalue={{ value: budget }}
                  color="active"
                />
              )}
            </>
          </GridElem>

          <DividerElem />

          {!formik.values.projectList?.length && (
            <DoubleContainerElem style={{ alignItems: 'start' }}>
              <SelectPreloadContainer
                formik={formik}
                name={FORM_VALUE_ENUM.PROJECT}
                value={getFieldValue(FORM_VALUE_ENUM.PROJECT)}
                onChange={setFieldValue}
                action={getExpenditureProjectList}
                convert={convertExpenditureListToSelect}
                title="EXPENDITURE.CREATE.PROJECT.TITLE"
                placeholder="EXPENDITURE.CREATE.PROJECT.PLACEHOLDER"
                errorMessage={getFieldError(FORM_VALUE_ENUM.PROJECT)}
                error={isFieldError(FORM_VALUE_ENUM.PROJECT)}
                dynamic
                required
              />
              <ButtonElem
                fill="outline"
                type="button"
                tid="EXPENDITURE.CREATE.PROJECT.ADD"
                iconLeft={
                  theme.type === THEME_ENUM.LIGHT ? addIconLight : addIcon
                }
                style={{ marginTop: '26px' }}
                onClick={() =>
                  formik.setFieldValue(FORM_VALUE_ENUM.PROJECT_LIST, [
                    {
                      id: '',
                      amount: 0,
                      amountGrn: 0,
                      percentage: 0,
                    },
                  ])
                }
              />
            </DoubleContainerElem>
          )}

          {!!formik.values.projectList?.length && (
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <React.Fragment>
                  <FieldArray
                    name="projectList"
                    render={(arrayHelpers) => (
                      <GridElem spacing={6}>
                        {formik.values.projectList.map(
                          (projectList: any, index: number) => {
                            const deleteProjectList = () => {
                              // if (formik.values.projectList.length > 1) {
                              arrayHelpers.remove(index);
                              // }
                            };
                            return (
                              <GridElem spacing={5}>
                                <ProjectListFieldContainer key={index}>
                                  <SelectPreloadContainer
                                    formik={formik}
                                    name={`${FORM_VALUE_ENUM.PROJECT_LIST}.${index}.id`}
                                    onChange={setFieldValue}
                                    value={
                                      formik.values?.projectList?.[index]?.id
                                    }
                                    onBlur={() =>
                                      formik.setFieldTouched(
                                        `${FORM_VALUE_ENUM.PROJECT_LIST}[${index}].id`,
                                        true,
                                      )
                                    }
                                    action={getExpenditureProjectList}
                                    convert={convertExpenditureListToSelect}
                                    title="EXPENDITURE.CREATE.PROJECT_LIST.TITLE"
                                    errorMessage={
                                      formik.touched?.projectList?.[index]
                                        ?.id &&
                                      formik.errors?.projectList?.[index]?.id
                                    }
                                    error={
                                      formik.touched?.projectList?.[index]
                                        ?.id &&
                                      formik.errors?.projectList?.[index]?.id
                                    }
                                    dynamic
                                    required
                                  />

                                  <FieldNumberElem
                                    name={`${FORM_VALUE_ENUM.PROJECT_LIST}.${index}.amount`}
                                    onChange={countPercentage}
                                    onBlur={formik.handleBlur}
                                    title="EXPENDITURE.FORM.AMOUNT_VALUTE"
                                    value={
                                      formik.values.projectList[index].amount
                                    }
                                    errorMessage={
                                      formik.touched?.projectList?.[index]
                                        ?.amount &&
                                      formik.errors?.projectList?.[index]
                                        ?.amount
                                    }
                                    error={
                                      formik.touched?.projectList?.[index]
                                        ?.amount &&
                                      formik.errors?.projectList?.[index]
                                        ?.amount
                                    }
                                    required
                                  />
                                  <FieldNumberElem
                                    name={`${FORM_VALUE_ENUM.PROJECT_LIST}.${index}.amountGrn`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    title="EXPENDITURE.FORM.AMOUNT_GRN"
                                    value={
                                      formik.values.projectList[index].amountGrn
                                    }
                                    errorMessage={
                                      formik.touched?.projectList?.[index]
                                        ?.amountGrn &&
                                      formik.errors?.projectList?.[index]
                                        ?.amountGrn
                                    }
                                    error={
                                      formik.touched?.projectList?.[index]
                                        ?.amountGrn &&
                                      formik.errors?.projectList?.[index]
                                        ?.amountGrn
                                    }
                                    required
                                  />
                                  <ProjectListControlContainer>
                                    <FieldNumberElem
                                      name={`${FORM_VALUE_ENUM.PROJECT_LIST}.${index}.percentage`}
                                      onChange={countAmount}
                                      onBlur={formik.handleBlur}
                                      title="EXPENDITURE.CREATE.PROJECT_LIST.PERCENTAGE"
                                      value={
                                        formik.values.projectList[index]
                                          .percentage
                                      }
                                      errorMessage={
                                        formik.touched?.projectList?.[index]
                                          ?.percentage &&
                                        formik.errors?.projectList?.[index]
                                          ?.percentage
                                      }
                                      error={
                                        formik.touched?.projectList?.[index]
                                          ?.percentage &&
                                        formik.errors?.projectList?.[index]
                                          ?.percentage
                                      }
                                      required
                                    />
                                    <ButtonElem
                                      type="button"
                                      iconLeft={CloseIcon}
                                      color="backgroundThird"
                                      onClick={deleteProjectList}
                                      style={{
                                        minWidth: '46px',
                                        width: '46px',
                                        padding: '15px',
                                        marginTop: '26px',
                                      }}
                                    />
                                  </ProjectListControlContainer>
                                </ProjectListFieldContainer>
                                <DividerElem />
                              </GridElem>
                            );
                          },
                        )}
                        <ProjectListFieldContainer>
                          <TextElem
                            tid="EXPENDITURE.CREATE.PROJECT_LIST.OUT"
                            color={getDifferenceAmount() ? 'active' : 'default'}
                          />
                          <TextElem
                            color={getDifferenceAmount() ? 'active' : 'default'}
                          >
                            {getDifferenceAmount()}
                          </TextElem>
                          <TextElem
                            color={getDifferenceAmount() ? 'active' : 'default'}
                          >
                            {getDifferenceAmountGrn()}
                          </TextElem>
                          <TextElem
                            color={getDifferenceAmount() ? 'active' : 'default'}
                          >
                            {getDifferencePercentage()}
                          </TextElem>
                        </ProjectListFieldContainer>

                        <ButtonElem
                          type="button"
                          onClick={() =>
                            arrayHelpers.push({
                              id: '',
                              amount: 0,
                              amountGrn: 0,
                              percentage: 0,
                            })
                          }
                          tid="EXPENDITURE.CREATE.PROJECT_LIST.ADD"
                          fill="outline"
                          iconLeft={
                            theme.type === THEME_ENUM.LIGHT
                              ? addIconLight
                              : addIcon
                          }
                          // disabled={isSubmitDisabled()}
                        />
                        {!!getDifferenceAmount() && (
                          <TextElem
                            tid="EXPENDITURE.CREATE.PROJECT_LIST.ERROR"
                            color="error"
                          />
                        )}
                      </GridElem>
                    )}
                  />
                </React.Fragment>
              </form>
            </FormikProvider>
          )}

          <DoubleContainerElem>
            <SelectElem
              name={FORM_VALUE_ENUM.STATUS}
              onChange={setFieldValue}
              options={EXPENDITURE_STATUS_OPTION_LIST}
              title="EXPENDITURE.FORM.STATUS.TITLE"
              errorMessage={getFieldError(FORM_VALUE_ENUM.STATUS)}
              error={isFieldError(FORM_VALUE_ENUM.STATUS)}
              value={setValue(
                EXPENDITURE_STATUS_OPTION_LIST,
                FORM_VALUE_ENUM.STATUS,
              )}
            />
            <ButtonElem
              type="submit"
              color="success"
              tid="EXPENDITURE.FORM.UPDATE"
              fill="solid"
              disabled={isSubmitDisabled || !!getDifferenceAmount()}
              style={{ marginTop: '26px' }}
            />
          </DoubleContainerElem>
        </GridElem>
      </GridElem>
      {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
      {isSuccess && <AlertActionElem type="success" tid="Success" />}
      {isLoading && <LoaderElem />}
    </FormElemCustom>
  );
};

const FormElemCustom = styled(FormElem)`
  cursor: default;
`;

const ProjectListControlContainer = styled(GridElem)`
  grid-template-columns: auto 46px;
  column-gap: ${Spacing(2)};
`;

const ProjectListFieldContainer = styled(GridElem)`
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media screen and (width<700px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (width<400px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
  column-gap: ${Spacing(2)};
`;

export const DeleteIconStyled = styled(DeleteIcon)`
  path {
    transition: all 0.2s;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Icon = styled.img`
  cursor: pointer;
`;
