import React from 'react';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { ExpenditureListContainer } from '../../epic/expenditure-list';
import { TextElem } from '../../common/text';
import styled from 'styled-components';
import { MenuAppContainer } from '../../epic/menu-app';
import { LayoutSectionElem } from '../../common/layout-section';

export const Page: React.FC = () => {
  return (
    <PageElem>
      <LayoutAppElem oneColumn>
        <MenuAppContainer />
        <LayoutElem size="default">
          <LayoutSectionElem spacing={8}>
            <ExpenditureListContainer />
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
    </PageElem>
  );
};

const LayoutCustom = styled(LayoutElem)``;
