import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const action = ({ pageParam, filter }: any) => {
  let params = '?';
  if (filter.status) {
    filter.status?.map((item: any) => (params += `&status=${item.value}`));
  }

  if (filter.type) {
    filter.type?.map((item: any) => (params += `&type=${item.value}`));
  }

  if (filter.bill) {
    filter.bill?.map((item: any) => (params += `&bill=${item.value}`));
  }

  if (filter.date) {
    params += `&range=${filter.date}`;
  }

  if (filter.departmant) {
    filter.departmant?.map(
      (item: any) => (params += `&departmant=${item.value}`),
    );
  }

  if (filter.responsible) {
    filter.responsible?.map(
      (item: any) => (params += `&responsible=${item.value}`),
    );
  }

  if (filter.executor) {
    filter.executor?.map((item: any) => (params += `&executor=${item.value}`));
  }

  if (pageParam) params += `&skip=${pageParam}`;
  return HttpRequest({
    method: API.TYPE,
    url: API.URL(params),
  });
};
