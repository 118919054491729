import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const getExpenditureProjectList = () => {
  return HttpRequest({
    method: API.PROJECT_LIST.TYPE,
    url: API.PROJECT_LIST.URL,
  });
};

export const getCategoryExpenseList = (uniqueNumber?: string) => {
  return HttpRequest({
    method: API.CATEGORY_EXPENSE_LIST.TYPE,
    url: API.CATEGORY_EXPENSE_LIST.URL(uniqueNumber),
  });
};

export const getAccountList = () => {
  return HttpRequest({
    method: API.ACCOUNT_LIST.TYPE,
    url: API.ACCOUNT_LIST.URL,
  });
};

export const getCounterpartyList = () => {
  return HttpRequest({
    method: API.COUNTERPARTY_LIST.TYPE,
    url: API.COUNTERPARTY_LIST.URL,
  });
};
