import React, { useCallback, useEffect, useState } from 'react';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
} from './constant';

import { Component } from './component';
import {
  number,
  numberPositiveMin,
  required,
  requiredIfArray,
  requiredIfNotEqual,
} from '../../lib/validation/service';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { action as fetch } from './action';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { convert } from './convert';
import * as Yup from 'yup';
import { i18n } from '../../lib/lang';
import { getExchangeRate } from '../../data/valute/action';
import { getUserData } from '../../data/user/action';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { useSelector } from '../../lib/store';
import { USER_ROLE } from '../../data/user/constant';

export const Container: React.FC<{}> = ({}) => {
  const exchangeRate: any = useQuery(['exchangeRate'], getExchangeRate);
  const userData: any = useQuery(['userData'], getUserData);
  const [uniqueNumber, setUniqueNumber] = useState('');

  const [budget, setBudget] = useState(0);
  const onSuccess = (d: any, values: any) => {
    userData.refetch();
    formik.resetForm();
  };

  const action = useMutation((values: FORM_VALUE_INTER) => fetch(values), {
    onSuccess,
  });

  const { state }: any = useSelector((s) => ({
    state: s[AUTH_MODULE_NAME],
  }));

  const isAdmin = state?.user?.role === USER_ROLE.ADMIN;

  const config = {
    [FORM_VALUE_ENUM.DESCRIPTION]: [required],
    [FORM_VALUE_ENUM.REQUISITES]: [required],
    [FORM_VALUE_ENUM.DEADLINE]: [required],
    [FORM_VALUE_ENUM.VALUTE]: [required],
    [FORM_VALUE_ENUM.AMOUNT]: [required, number, numberPositiveMin(0)],
    [FORM_VALUE_ENUM.BILL]: [required],
    [FORM_VALUE_ENUM.DEPARTMENT]: isAdmin ? [required] : [],
    [FORM_VALUE_ENUM.RESPONSIBLE]: isAdmin ? [required] : [],
    [FORM_VALUE_ENUM.COMMENT_FILE]: [],
    [FORM_VALUE_ENUM.CATEGORY]: [],
    [FORM_VALUE_ENUM.PROJECT]: [requiredIfArray(FORM_VALUE_ENUM.PROJECT_LIST)],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const initialValues = {
    [FORM_VALUE_ENUM.DESCRIPTION]: undefined,
    [FORM_VALUE_ENUM.REQUISITES]: undefined,
    [FORM_VALUE_ENUM.DEADLINE]: '',
    [FORM_VALUE_ENUM.VALUTE]: '',
    [FORM_VALUE_ENUM.AMOUNT]: 0,
    [FORM_VALUE_ENUM.CATEGORY]: '',
    [FORM_VALUE_ENUM.BILL]: '',
    // [FORM_VALUE_ENUM.DEPARTMENT]: '',
    // [FORM_VALUE_ENUM.RESPONSIBLE]: '',
    [FORM_VALUE_ENUM.COMMENT_FILE]: '',
    [FORM_VALUE_ENUM.CONFIRM_FILE]: '',
    [FORM_VALUE_ENUM.STATUS]: '',
    [FORM_VALUE_ENUM.PROJECT]: '',
    [FORM_VALUE_ENUM.PROJECT_LIST]: [],
  };

  const validationSchema = Yup.object().shape({
    projectList: Yup.array().of(
      Yup.object().shape({
        id: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
        amount: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
        percentage: Yup.string().required(i18n.t('VALIDATION.REQUIRED')),
      }),
    ),
  });

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    validationSchema,
    onSubmit: (values: FORM_VALUE_INTER) => {
      return action.mutate(convert(values));
    },
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  useEffect(() => {
    setBudget(userData?.data?.project?.restBudget);
  }, [userData?.data?.project?.restBudget]);

  const checkBudget = () => {
    if (
      exchangeRate.isSuccess &&
      userData.isSuccess &&
      formik.values[FORM_VALUE_ENUM.AMOUNT] &&
      formik.values[FORM_VALUE_ENUM.VALUTE]
    ) {
      if (
        exchangeRate?.data[formik.values[FORM_VALUE_ENUM.VALUTE]] *
          formik.values[FORM_VALUE_ENUM.AMOUNT] >
        budget
      ) {
        return true;
      }
    }
    return false;
  };

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }

    if (
      !formik.values[FORM_VALUE_ENUM.PROJECT_LIST]?.length &&
      !formik.values[FORM_VALUE_ENUM.PROJECT]
    ) {
      return true;
    }

    if (checkBudget()) {
      return true;
    }

    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];
  const setFieldValue = useCallback(
    (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) => {
      formik.setFieldValue(name, e.value);
      if (e?.restBudget !== undefined && e?.restBudget !== budget) {
        setBudget(e.restBudget);
      }
      if (e?.uniqueNumber !== undefined && e?.uniqueNumber !== uniqueNumber) {
        setUniqueNumber(e.uniqueNumber);
      }
    },
    [budget, uniqueNumber, formik],
  );

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };

  const onSuccessUpload = (id: string, name: string) => {
    formik.setFieldValue(name, id);
  };
  console.log(formik.values, isSubmitDisabled(), 'checkFormik');
  return (
    <Component
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      formik={formik}
      isError={isError()}
      isLoading={isLoading()}
      errorMessage={getErrorMessage()}
      setFieldValue={setFieldValue}
      setValue={setValue}
      isSuccess={isSuccess()}
      onSuccessUpload={onSuccessUpload}
      budget={budget}
      userData={userData}
      checkBudget={checkBudget()}
      isAdmin={isAdmin}
      uniqueNumber={uniqueNumber}
    />
  );
};
