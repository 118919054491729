import { CURRENCY_TYPE } from '../valute/constant';
import {
  EXPENDITURE_BILL,
  EXPENDITURE_ITEM_DATA,
  EXPENDITURE_ITEM_DATA_RAW,
  EXPENDITURE_ITEM_LIST_DATA,
} from './constant';

export const convertExpenditure = (
  expenditure: EXPENDITURE_ITEM_DATA_RAW,
): EXPENDITURE_ITEM_DATA => {
  return {
    ...expenditure,
    departmant: {
      ...expenditure?.departmant,
      name: `${expenditure?.departmant?.name} ${expenditure?.departmant?.uniqueNumber}`,
    },
    isWarning:
      expenditure.bill === EXPENDITURE_BILL.CRYPTO &&
      expenditure.valute !== CURRENCY_TYPE.USD &&
      expenditure.valute !== CURRENCY_TYPE.USDT,
  };
};

export const convertCategoryToSelect = (
  list: { id: string; label: string }[],
) => {
  return list
    .filter(
      (item) =>
        /\b\d+\b$/.test(item.label.trim()) &&
        !item.label.toLowerCase().includes('disabled'),
    )
    .map((item) => ({ value: item.id, label: item.label }));
};

export const convertExpenditureListToSelect = (
  list: { id: string; label: string }[],
) => {
  return list.map((item) => ({ value: item.id, label: item.label }));
};

export const convertExpenditureList = (
  expenditureList: EXPENDITURE_ITEM_DATA_RAW[],
): EXPENDITURE_ITEM_LIST_DATA => {
  return {
    list: expenditureList?.map((expenditure: EXPENDITURE_ITEM_DATA_RAW) => {
      return convertExpenditure(expenditure);
    }),
    isEmpty: !expenditureList?.length,
  };
};
