import { useState } from 'react';
import { SelectElem } from '../../../common/select';
import {
  EXPENDITURE_BILL_OPTION_LIST,
  EXPENDITURE_DEPARTMENT_OPTION_LIST,
  EXPENDITURE_STATUS_OPTION_LIST,
  EXPENDITURE_TYPE_OPTION_LIST,
} from '../../../data/expenditure/constant';
import { SelectPreloadContainer } from '../../select-preload';
import { getAllUserList } from '../../../data/user/action';
import { convertUserListToSelect } from '../../../data/user/convert';
import { COLOR_ENUM } from '../../../theme/color';
import styled from 'styled-components';
import { FlexElem } from '../../../common/flex';
import { GridElem } from '../../../common/grid';
import { FieldDataTimeRangeElem } from '../../../common/field-datetime-range';
import { TextElem } from '../../../common/text';
import { Spacing } from '../../../theme';
import { ReactComponent as IndicatorIcon } from '../../../asset/svg/common/selectIndicator.svg';
import { ReactComponent as CloseIcon } from '../../../asset/svg/common/close.svg';
import { getProjectList } from '../../../data/project/action';
import { convertProjectListToSelect } from '../../../data/project/convert';

export const FilterExpenditureList: React.FC<{
  filter: any;
  setFilter: any;
}> = ({ filter, setFilter }) => {
  const onChange = (name: any, e: any) => {
    console.log(name, e);
    if (!e) {
      setFilter({ ...filter, date: name.target.value });
    } else {
      setFilter({ ...filter, [name]: e });
    }
  };

  const [visible, setVisible] = useState(false);

  const handleOpen = () => {
    setVisible(true);
  };
  const handleClose = (e: any) => {
    e.stopPropagation();
    setVisible(false);
  };

  const multiValueContainer = ({ selectProps, data }: any) => {
    const label = data.label;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex(
      (selected: any) => selected.label === label,
    );
    const isLastSelected = index === allSelected.length - 1;
    const labelSuffix = isLastSelected ? '' : ', ';
    const val = `${label}${labelSuffix}`;
    return val;
  };

  return (
    <Container spacing={4} onClick={handleOpen}>
      <TitleContainer>
        <TextElem tid="EXPENDITURE.FILTER.TITLE" color="textThird" />
        {visible ? <CloseIcon onClick={handleClose} /> : <IndicatorIcon />}
      </TitleContainer>
      {visible && (
        <FilterContainer style={{ flexWrap: 'wrap' }}>
          <SelectElem
            name="status"
            onChange={onChange}
            value={filter.status}
            options={EXPENDITURE_STATUS_OPTION_LIST}
            customComponents={{ MultiValueContainer: multiValueContainer }}
            isMulti
            placeholder="EXPENDITURE.FILTER.PLACEHOLDER.STATUS"
          />

          <SelectElem
            name="type"
            onChange={onChange}
            value={filter.type}
            options={EXPENDITURE_TYPE_OPTION_LIST}
            customComponents={{ MultiValueContainer: multiValueContainer }}
            isMulti
            placeholder="EXPENDITURE.FILTER.PLACEHOLDER.TYPE"
          />
          <SelectElem
            onChange={onChange}
            name="bill"
            options={EXPENDITURE_BILL_OPTION_LIST}
            value={filter.bill}
            customComponents={{ MultiValueContainer: multiValueContainer }}
            isMulti
            placeholder="EXPENDITURE.FILTER.PLACEHOLDER.BILL"
          />
          <FieldDataTimeRangeElem
            showTime={false}
            onChange={onChange}
            name={'date'}
          />

          <SelectCustom
            name="departmant"
            onChange={onChange}
            action={getProjectList}
            convert={convertProjectListToSelect}
            customComponents={{ MultiValueContainer: multiValueContainer }}
            value={filter.departmant}
            isMulti
            dynamic
            placeholder="EXPENDITURE.FILTER.PLACEHOLDER.DEPARTMENT"
          />
          <SelectCustom
            name="responsible"
            onChange={onChange}
            action={getAllUserList}
            convert={convertUserListToSelect}
            customComponents={{ MultiValueContainer: multiValueContainer }}
            value={filter.responsible}
            isMulti
            placeholder="EXPENDITURE.FILTER.PLACEHOLDER.RESPONSIBLE"
            dynamic
          />

          <SelectCustom
            name="executor"
            onChange={onChange}
            action={getAllUserList}
            convert={convertUserListToSelect}
            customComponents={{ MultiValueContainer: multiValueContainer }}
            value={filter.executor}
            isMulti
            placeholder="EXPENDITURE.FILTER.PLACEHOLDER.EXECUTER"
            dynamic
          />
        </FilterContainer>
      )}
    </Container>
  );
};

const Container = styled(GridElem)`
  padding: ${Spacing(4)};
  border-radius: 10px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  cursor: pointer;
`;
const TitleContainer = styled(FlexElem)`
  justify-content: space-between;
`;

const FilterContainer = styled(GridElem)`
  grid-template-columns: repeat(4, 1fr);
`;

const SelectCustom = styled(SelectPreloadContainer)`
  .Select__value-container--is-multi {
    display: flex;
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};

    width: 119px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .Select__value-container--has-value {
    display: block;
  }

  .Select__menu {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  /* .Select__control {
    margin: auto;
    background: ${({ theme }) =>
    theme[COLOR_ENUM.DEFAULT] === '#1B1924'
      ? theme[COLOR_ENUM.INPUT_BACKGROUND]
      : theme[COLOR_ENUM.INPUT]};
  } */
`;
