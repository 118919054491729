export const MODULE_NAME = 'PERSON_ITEM_CREATE_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  DESCRIPTION = 'description',
  REQUISITES = 'requisites',
  DEADLINE = 'deadLine',
  VALUTE = 'valute',
  AMOUNT = 'amount',
  CATEGORY = 'category',
  BILL = 'bill',
  DEPARTMENT = 'departmant',
  RESPONSIBLE = 'responsible',
  CONFIRM_FILE = 'confirmFile',
  COMMENT_FILE = 'commentFile',
  STATUS = 'status',
  COMMENT = 'comment',
  PROJECT_LIST = 'projectList',
  PROJECT = 'project',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.DESCRIPTION]?: string;
  [FORM_VALUE_ENUM.REQUISITES]?: string;
  [FORM_VALUE_ENUM.DEADLINE]?: string;
  [FORM_VALUE_ENUM.VALUTE]?: string;
  [FORM_VALUE_ENUM.AMOUNT]?: number;
  [FORM_VALUE_ENUM.CATEGORY]?: string;
  [FORM_VALUE_ENUM.BILL]?: string;
  [FORM_VALUE_ENUM.DEPARTMENT]?: string;
  [FORM_VALUE_ENUM.RESPONSIBLE]?: string;
  [FORM_VALUE_ENUM.STATUS]?: string;
  [FORM_VALUE_ENUM.CONFIRM_FILE]?: string;
  [FORM_VALUE_ENUM.COMMENT_FILE]?: string;
  [FORM_VALUE_ENUM.COMMENT]?: string;
  [FORM_VALUE_ENUM.PROJECT]?: string;
  [FORM_VALUE_ENUM.PROJECT_LIST]?: {
    id: string;
    amount: string;
    percentage: string;
  }[];
}

export const API = {
  TYPE: 'POST',
  URL: `/expenditure/create`,
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
