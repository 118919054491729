import React from 'react';
import { LayoutSectionElem } from '../../common/layout-section';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { MenuAppContainer } from '../../epic/menu-app';
import icon from '../../asset/svg/header/project.svg';

import { UserListContainer } from '../../epic/user-list';
import { HeaderContainer } from '../../epic/header';

export const Page: React.FC = () => {
  return (
    <PageElem>
      <LayoutAppElem>
        <MenuAppContainer />
        <LayoutElem>
          <HeaderContainer icon={icon} title="USER.TITLE" />

          <LayoutSectionElem spacing={8}>
            <UserListContainer />
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
    </PageElem>
  );
};
