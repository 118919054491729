import { HttpRequest } from '../../lib/http';
import { API } from './constant';

export const getUserList = () => {
  return HttpRequest({
    method: API.LIST.TYPE,
    url: API.LIST.URL,
  });
};

export const getAllUserList = () => {
  return HttpRequest({
    method: API.LIST_2.TYPE,
    url: API.LIST_2.URL,
  });
};

export const getUserData = () => {
  return HttpRequest({
    method: API.DATA.TYPE,
    url: API.DATA.URL,
  });
};
