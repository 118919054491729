import React from 'react';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { ExpenditureItemCreateContainer } from '../../epic/expenditure-item-create';
import { LayoutSectionElem } from '../../common/layout-section';
import { HeaderContainer } from '../../epic/header';
import { MenuAppContainer } from '../../epic/menu-app';
import icon from '../../asset/svg/header/expenditure-create.svg';

export const Page: React.FC = () => {
  return (
    <PageElem>
      <LayoutAppElem>
        <MenuAppContainer />
        <LayoutElem>
          <HeaderContainer icon={icon} title="EXPENDITURE.CREATE.TITLE" />
          <LayoutSectionElem spacing={5}>
            <ExpenditureItemCreateContainer />
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
    </PageElem>
  );
};
