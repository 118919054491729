import { FORM_VALUE_ENUM, FORM_VALUE_INTER } from './constant';

export const convert = (data: FORM_VALUE_INTER) => {
  return {
    ...data,
    [FORM_VALUE_ENUM.DESCRIPTION]: data[FORM_VALUE_ENUM.DESCRIPTION].trim(),
    [FORM_VALUE_ENUM.AMOUNT_GRN]: Number(data[FORM_VALUE_ENUM.AMOUNT_GRN]),
    [FORM_VALUE_ENUM.AMOUNT]: Number(data[FORM_VALUE_ENUM.AMOUNT]),
    [FORM_VALUE_ENUM.EXCHANGE_RATE]: Number(
      data[FORM_VALUE_ENUM.EXCHANGE_RATE],
    ),
    [FORM_VALUE_ENUM.PROJECT_LIST]: data[FORM_VALUE_ENUM.PROJECT_LIST]?.map(
      (item) => ({
        id: item.id,
        amount: Number(item.amount),
        amountGrn: Number(item.amountGrn),
        percentage: Number(item.percentage),
        operationId: item.operationId,
      }),
    ),

    [FORM_VALUE_ENUM.REQUISITES]: data[FORM_VALUE_ENUM.REQUISITES].trim(),
    [FORM_VALUE_ENUM.COMMENT]: data[FORM_VALUE_ENUM.COMMENT]?.trim(),
  };
};
