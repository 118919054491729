export function preciseCalculation(
  num1: number,
  operator: string,
  num2: number,
) {
  // Умножаем числа на 10 (или больше, в зависимости от точности) для работы с целыми числами
  const precision = 10; // Умножение на 10 для 1 знака после запятой
  const factor = Math.pow(10, precision);

  const n1 = Math.round(num1 * factor);
  const n2 = Math.round(num2 * factor);

  let result;

  switch (operator) {
    case '+':
      result = (n1 + n2) / factor;
      break;
    case '-':
      result = (n1 - n2) / factor;
      break;
    case '*':
      result = (n1 * n2) / (factor * factor);
      break;
    case '/':
      if (n2 === 0) {
        throw new Error('Деление на ноль невозможно');
      }
      result = n1 / n2;
      break;
    default:
      throw new Error('Недопустимый оператор');
  }

  return Number(result.toFixed(6));
}
