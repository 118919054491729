import React, { ReactNode, useEffect, useState } from 'react';

import { FilePicker, PickedFile } from '@capawesome/capacitor-file-picker';
import { useMutation, useQueryClient } from 'react-query';

import { FILE_ITEM_DATA_RAW } from '../../data/file/constant';
import {
  allowedFileTypes,
  allowedImageTypes,
  allowedVideoTypes,
  API,
} from './constant';
import { HttpRequest } from '../../lib/http';
import { useDropzone } from 'react-dropzone';
import { ImageComponent } from './view/image';
import { FileComponent } from './view/file';
import { i18n } from '../../lib/lang';

export const Container: React.FC<{
  children?: ReactNode;
  onSuccess?: Function;
  onReset?: Function;
  errorMessage?: string;
  name?: string;
  defaultValue?: string;
  type?: 'file' | 'image' | 'video';
  description?: boolean;
  publicUrl?: boolean;
  resetAfterUpload?: boolean;
  maxDuration?: number;
  maxSize?: number;
  maxDurationText?: string;
  maxSizeText?: string;
}> = ({
  defaultValue,
  children,
  name,
  onSuccess,
  onReset,
  errorMessage,
  type = 'file',
  description = true,
  publicUrl = false,
  resetAfterUpload = false,
  maxSize,
  maxDuration,
  maxDurationText,
  maxSizeText,
}) => {
  const query = useQueryClient();

  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState<any>('');
  const [progress, setProgress] = useState(0);
  const [value, setValue] = useState(defaultValue);
  const [modalPreviewVisible, setModalPreviewVisible] = useState(false);
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const getVideoDuration = (file: File): Promise<number> =>
    new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.src = URL.createObjectURL(file);
      video.onloadedmetadata = () => {
        resolve(video.duration);
        URL.revokeObjectURL(video.src);
      };
      video.onerror = reject;
    });

  const openModalPreview = (e: any) => {
    e.stopPropagation();
    setModalPreviewVisible(true);
  };

  const closeModalPreview = (e: any) => {
    e.stopPropagation();

    setModalPreviewVisible(false);
  };

  const action = async (file: File) => {
    const fileData = file; // Оригинальный файл уже содержит бинарные данные.
    const fileName = file.name; // Имя файла.

    if (file instanceof File) {
      const fileType = file.type;

      // Проверки типов файлов
      if (type === 'image' && !allowedImageTypes.includes(fileType)) {
        setError(i18n.t('FILE.UPLOAD.ERROR.TYPE.IMAGE'));
        return Promise.reject('Invalid image type');
      } else if (type === 'video' && !allowedVideoTypes.includes(fileType)) {
        setError(i18n.t('FILE.UPLOAD.ERROR.TYPE.VIDEO'));
        return Promise.reject('Invalid video type');
      } else if (type === 'file' && !allowedFileTypes.includes(fileType)) {
        setError(i18n.t('FILE.UPLOAD.ERROR.TYPE.FILE'));
        return Promise.reject('Invalid file type');
      }

      // Проверка длительности видео
      if (type === 'video' && maxDuration) {
        const duration = await getVideoDuration(file);
        if (duration > maxDuration) {
          setError(
            i18n.t('FILE.UPLOAD.ERROR.TYPE.DURATION', {
              duration: maxDurationText,
            }),
          );
          return Promise.reject('Video duration exceeds maximum limit');
        }
      }

      // Проверка размера файла
      if (maxSize && file.size > maxSize) {
        setError(i18n.t('FILE.UPLOAD.ERROR.SIZE', { size: maxSizeText }));
        return Promise.reject('File size exceeds maximum limit');
      }

      // Если ошибок нет, сбрасываем состояние ошибки
      setError('');

      // Выполняем запрос на отправку файла.
      const response = await HttpRequest({
        method: API.MAIN.TYPE,
        url: publicUrl ? API.MAIN.PUBLIC_URL : API.MAIN.URL,
        data: fileData, // Отправляем бинарные данные файла.
        headers: {
          'Content-Type': file.type, // Тип файла.
          'X-File-Name': encodeURIComponent(fileName), // Добавляем имя файла в заголовок (или используем другой заголовок, например, 'X-File-Name').
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setProgress(progress);
        },
        onDownloadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100;
          setProgress(progress);
        },
      });

      setIsSuccess(true);
      setProgress(0);
      return response;
    }

    return Promise.reject('Invalid file');
  };

  const uploadFile = useMutation(
    (data: any) => {
      return action(data);
    },
    {
      onSuccess: (d: any) => {
        if (onSuccess) {
          if (resetAfterUpload) {
            uploadFile.reset();
          }
          console.log(d);
          onSuccess(d.id, name);
        }
      },
    },
  );

  const getFile = async () => {
    try {
      const result = await FilePicker.pickFiles({
        multiple: false,
        readData: true,
      });
      console.log(result, 'result');
      return result.files[0];
    } catch (err) {
      console.log(err);
    }
  };

  const handleClick = async (e: any) => {
    e.stopPropagation();
    if (!(!!uploadFile.isSuccess || (!!value && !uploadFile.isLoading))) {
      const file = await getFile();
      console.log(file, 'uploadedFile');

      if (file) {
        appendFileToFormData(file);
      }
    }
    e.preventDefault();
  };

  const handleReselect = async (e: any) => {
    e.stopPropagation();
    const file = await getFile();
    if (file) {
      appendFileToFormData(file);
    }
    if (onReset) {
      onReset(name);
    }
  };

  const handleReset = (e: any) => {
    e.stopPropagation();

    uploadFile.reset();
    if (value) {
      setValue(undefined);
    }
    if (onReset) {
      onReset(name);
    }
    if (onSuccess) {
      onSuccess(null, name);
    }
  };

  const appendFileToFormData = async (file: PickedFile) => {
    console.log(file, 'appendFileToFormData');
    try {
      if (file?.blob) {
        const rawFile = new File([file.blob as BlobPart], file.name, {
          type: file.mimeType,
        });

        uploadFile.mutate(rawFile);
      } else if (file?.data && !file?.blob) {
        const base64Response = await fetch(
          `data:${file.mimeType};base64,${file?.data}`,
        );

        const blob = await base64Response.blob();

        const rawFile = new File([blob as BlobPart], file.name, {
          type: file.mimeType,
        });

        uploadFile.mutate(rawFile);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getData = (): FILE_ITEM_DATA_RAW | undefined => {
    const data = uploadFile.data as unknown as FILE_ITEM_DATA_RAW;

    if (data) {
      return data;
    }
  };
  const data = getData();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // const formData = new FormData();
      // formData.append('file', acceptedFiles[0]);
      uploadFile.mutate(acceptedFiles[0]);
    },
    noClick: true,
  });

  {
    switch (type) {
      case 'image':
        return (
          <ImageComponent
            data={data}
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            uploadFile={uploadFile}
            defaultValue={value}
            handleClick={handleClick}
            //@ts-ignore
            error={error || uploadFile?.error?.message}
            progress={progress}
            handleReselect={handleReselect}
            handleReset={handleReset}
            modalPreviewVisible={modalPreviewVisible}
            openModalPreview={openModalPreview}
            closeModalPreview={closeModalPreview}
            description={description}
          />
        );

      default:
        return (
          <FileComponent
            data={data}
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            uploadFile={uploadFile}
            defaultValue={value}
            handleClick={handleClick}
            //@ts-ignore
            error={error || uploadFile?.error?.message}
            progress={progress}
            handleReselect={handleReselect}
            handleReset={handleReset}
          />
        );
    }
  }
};
