export enum CURRENCY_TYPE {
  USD = 'USD',
  EUR = 'EUR',
  UAH = 'UAH',
  PLN = 'PLN',
  AED = 'AED',
  USDT = 'USDT',
  KZT = 'KZT',
}

export const CURRENCY_TYPE_SYMBOL = {
  [CURRENCY_TYPE.USD]: '$',
  [CURRENCY_TYPE.EUR]: '€',
  [CURRENCY_TYPE.UAH]: '₴',
  [CURRENCY_TYPE.PLN]: 'zł',
  [CURRENCY_TYPE.AED]: 'د.إ',
  [CURRENCY_TYPE.USDT]: '$',
  [CURRENCY_TYPE.KZT]: '₸',
};

export const API = {
  TYPE: 'GET',
  URL: '/expenditure/exchangeRate',
};
