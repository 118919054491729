import { i18n } from '../../lib/lang';
import { PROJECT_ITEM_DATA_RAW } from '../project/constant';

export interface USER_ITEM_DATA_RAW {
  id: string;
  createDate: string;
  email: string;
  role: USER_ROLE;
  password: string;
  confirmAccount?: boolean;
  name: string;
  telegramId: string;
  token?: string;
  project: PROJECT_ITEM_DATA_RAW;
}

export interface USER_ITEM_LIST_DATA_RAW {
  list: USER_ITEM_DATA_RAW[];
}

export interface USER_DATA extends USER_ITEM_DATA_RAW {}

export interface USER_ITEM_LIST_DATA {
  list: USER_DATA[];
  isEmpty: boolean;
}

export const API = {
  LIST: {
    TYPE: 'GET',
    URL: '/user/admin/user',
  },
  LIST_2: {
    TYPE: 'GET',
    URL: '/user/all/list',
  },
  DATA: {
    TYPE: 'GET',
    URL: '/user/data',
  },
};

export enum USER_ROLE {
  BLOCKED = 'BLOCKED',
  MODER = 'MODER',
  ADMIN = 'ADMIN',
  ACCOUNTANT = 'ACCOUNTANT',
  MANAGER = 'MANAGER',
  SPEAKER = 'SPEAKER',
}

export const USER_ROLE_OPTION_LIST = [
  {
    value: USER_ROLE.ADMIN,
    label: `${i18n.t('USER.ROLE.ADMIN')}`,
  },

  {
    value: USER_ROLE.ACCOUNTANT,
    label: `${i18n.t('USER.ROLE.ACCOUNTANT')}`,
  },
  {
    value: USER_ROLE.MANAGER,
    label: `${i18n.t('USER.ROLE.MANAGER')}`,
  },
];
