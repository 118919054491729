import React, { useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery, useQuery } from 'react-query';

import { ACTION_ERROR_INTER, DATA, MODULE_NAME } from './constant';
import { Component } from './component';
import { action } from './action';
import { convertExpenditureList } from '../../data/expenditure/convert';

export const Container: React.FC<{}> = ({}) => {
  const [count, setCount] = useState();
  const [exchangeRate, setExchangeRate] = useState();

  const [filter, setFilter] = useState({
    status: null,
    type: null,
    bill: null,
    date: null,
    departmant: null,
    responsible: null,
    executor: null,
  });

  const preFetch = useInfiniteQuery({
    queryKey: [MODULE_NAME],
    queryFn: ({ pageParam = 1 }) =>
      action({
        pageParam,
        filter,
      }),
    getNextPageParam: (lastPage: any, allPages: any) => {
      console.log(allPages, lastPage);
      return lastPage.list.length ? allPages.length + 1 : undefined;
    },
    retry: 0,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const data = useMemo(() => {
    console.log(preFetch?.data);
    if (preFetch?.data?.pages) {
      console.log(preFetch?.data?.pages[0]);
      //@ts-ignore
      setCount(preFetch?.data?.pages[0]?.total);
      //@ts-ignore
      setExchangeRate(preFetch?.data?.pages[0]?.exchangeRate);
    }

    return convertExpenditureList(
      //@ts-ignore
      preFetch?.data?.pages?.reduce((acc: any, page: any) => {
        return [...acc, ...page.list];
      }, []),
    );
  }, [preFetch?.data, preFetch?.isFetching]);

  useEffect(() => {
    preFetch.refetch();
  }, [filter]);

  const isError = () => {
    if (preFetch.isError && !preFetch.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = preFetch.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  console.log(count);

  return (
    <Component
      data={data}
      isError={isError()}
      errorMessage={getErrorMessage()}
      filter={filter}
      setFilter={setFilter}
      fetchNextPage={preFetch.fetchNextPage}
      isFetching={preFetch.isFetching}
      hasNextPage={preFetch.hasNextPage}
      count={count}
      isLoading={preFetch.isLoading}
      exchangeRate={exchangeRate}
    />
  );
};
